import React from 'react'
import { useAuth } from '../../../app/useAuth'
import EarnedBadges from './EarnedBadges'

const EarnedBadgesContainer = () => {
    const { principalPubkey } = useAuth()

    return <EarnedBadges targetPubkey={principalPubkey!} />
}

export default React.memo(EarnedBadgesContainer)
