import { Backdrop, CircularProgress } from '@mui/material'
import { useNostrEvents } from 'nostr-react'
import { nip19 } from 'nostr-tools'
import { AddressPointer } from 'nostr-tools/nip19'
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { sortBy } from 'sort-by-typescript'
import { NostrReactEvent } from '../../../app/types'
import { useAuth } from '../../../app/useAuth'
import useBadge from '../../../app/useBadge'

type AwardedUsersContextState = {
    rawAwardedUserEvents: NostrReactEvent[]
    recipientsList: string[]
    isLoadingUserEvents: boolean
}

export const AwardedUsersContext = createContext({} as AwardedUsersContextState)

type Props = { children: React.ReactNode }

export const AwardedUsersContextProvider: React.FC<Props> = ({ children }) => {
    const { getPubkey } = useAuth()
    const { badgeNaddr } = useBadge()
    const isFirstRender = useRef(true)
    const isFirstLoad = useRef(true)
    const [isLoading, setIsLoading] = useState(true)

    if (!getPubkey()) throw new Error('Awarded Users Context: Pubkey not found')
    const principalPubkey = getPubkey()!

    // console.log('Awarded Users Context: principalPubkey', principalPubkey)
    // console.log('Awarded Users Context: badgeNaddr', badgeNaddr)

    const addressPointer = nip19.decode(badgeNaddr).data as AddressPointer
    const aTagValue = `${addressPointer.kind}:${addressPointer.pubkey}:${addressPointer.identifier}`
    // console.log('Use Awarded Users: aTagValue:', aTagValue)

    const {
        events: rawAwardedUserEvents,
        onEvent,
        isLoading: isLoadingUserEvents,
    } = useNostrEvents({
        filter: {
            kinds: [8],
            authors: [principalPubkey!],
            '#a': [aTagValue],
        },
    })

    const updateShowSpinner = () => {
        if (isLoading && isFirstLoad.current) {
            isFirstLoad.current = false
            // Give the component time to marshall the events into something it can use
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
            // setIsLoading(false)
        }
    }

    useEffect(() => {
        // console.log(
        //     'Awarded Users Context: Load Users: Is Loading Events:',
        //     isLoadingUserEvents
        // )
        if (!isLoadingUserEvents) {
            updateShowSpinner()
        }
    }, [isLoadingUserEvents])

    const listenToEvents = useCallback(() => {
        onEvent((event) => {
            // console.log('Awarded Users Context: Load Users: Event:', event)
            updateShowSpinner()
        })
    }, [onEvent, updateShowSpinner])

    useEffect(() => {
        if (isFirstRender.current) {
            // console.log(
            //     'Awarded Users Context: Load Users: Use Effect: Listen to Events called...'
            // )
            listenToEvents()
            isFirstRender.current = false
        }
    })

    const recipientsList = useMemo(() => {
        return Object.keys(
            rawAwardedUserEvents.reduce((map, item) => {
                const key = item.tags[1][1]
                return {
                    ...map,
                    [`${key}`]: item,
                }
            }, {})
        )
    }, [rawAwardedUserEvents])

    // useEffect(() => {
    //     console.log('Awarded Users Context: Recipients List:', recipientsList)
    // }, [recipientsList])

    useEffect(() => {
        // console.log(
        //     'Awarded Users Context: Raw Awarded User Events:',
        //     rawAwardedUserEvents
        // )
    }, [rawAwardedUserEvents])

    const value = useMemo(() => {
        return { rawAwardedUserEvents, recipientsList, isLoadingUserEvents }
    }, [rawAwardedUserEvents, recipientsList, isLoadingUserEvents])

    return (
        <AwardedUsersContext.Provider value={value}>
            {children}
        </AwardedUsersContext.Provider>
    )
}

export const useAwardedUsers = () => {
    return useContext(AwardedUsersContext)
}
