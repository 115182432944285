import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React from 'react'
import { useAuth } from '../../app/useAuth'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import MenuProfileCardContainer from '../Cards/ProfileCardContainer'

const Masthead = () => {
    const { signout, getPubkey } = useAuth()
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [navAnchorEl, setNavAnchorEl] = React.useState<null | HTMLElement>(
        null
    )
    const openNav = Boolean(navAnchorEl)
    const targetPubkey = getPubkey()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClickNav = (event: React.MouseEvent<HTMLElement>) => {
        setNavAnchorEl(event.currentTarget)
    }
    const handleCloseNav = () => {
        setNavAnchorEl(null)
    }

    const ITEM_HEIGHT = 48

    return (
        <Box
            sx={{
                position: 'fixed',
                zIndex: '100',
                width: 'inherit',
                maxWidth: '442px',
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'top',
                justifyContent: 'space-between',
                mb: '1rem',
                p: '1rem',
                backgroundColor: '#eeeeee',
                // border: '1px solid black',
                borderRadius: 0,
                boxShadow: '3',
            }}
        >
            <Box
                sx={{
                    pl: '.25rem',
                    // width: '4rem',
                    // border: '1px solid gray',
                }}
            >
                <IconButton
                    id='menu-button'
                    aria-label='menu'
                    aria-controls={openNav ? 'nav-menu' : undefined}
                    aria-expanded={openNav ? 'true' : undefined}
                    aria-haspopup='true'
                    edge='start'
                    color='inherit'
                    sx={{ mt: '.4rem' }}
                    onClick={handleClickNav}
                >
                    <MenuIcon sx={{ height: '2rem', width: '2rem' }} />
                </IconButton>
                <Menu
                    id='nav-menu'
                    MenuListProps={{
                        'aria-labelledby': 'menu-button',
                    }}
                    anchorEl={navAnchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={openNav}
                    onClose={handleCloseNav}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    <MenuItem
                        key='my-profile'
                        onClick={() => {
                            navigate(`/badge/creations`)
                            handleCloseNav()
                        }}
                    >
                        Badges I've Created
                    </MenuItem>
                    <MenuItem
                        key='find-user'
                        onClick={() => {
                            navigate('/badges/earned')
                            handleCloseNav()
                        }}
                    >
                        Badges I've Earned
                    </MenuItem>
                </Menu>
            </Box>

            <Box
                display='flex'
                alignItems='center'
                sx={{ border: '0px solid gray' }}
            >
                <Box
                    component='img'
                    src='/HeartBadge002.png'
                    sx={{
                        ml: '.5rem',
                        mr: '.25rem',
                        width: '31px',
                        height: '31px',
                    }}
                />
                <Typography
                    display='flex'
                    variant='h5'
                    fontWeight='fontWeightBold'
                    sx={{
                        pb: '0',
                        color: 'rebeccapurple',
                        // ml: '1rem',
                    }}
                >
                    Nostr
                </Typography>

                <Typography
                    variant='h5'
                    fontWeight='fontWeightBold'
                    sx={{
                        color: 'rebeccapurple',
                    }}
                >
                    Badges
                </Typography>
            </Box>

            <Box
                sx={{
                    width: '4rem',
                    textAlign: 'right',
                }}
            >
                <IconButton
                    id='long-button'
                    aria-label='more'
                    aria-controls={open ? 'long-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup='true'
                    edge='start'
                    color='inherit'
                    onClick={handleClick}
                    sx={{ border: '1px solid transparent' }}
                >
                    <MenuProfileCardContainer
                        key={targetPubkey}
                        pubkey={targetPubkey!}
                    />
                    {/* <MoreVert /> */}
                </IconButton>
                <Menu
                    id='long-menu'
                    MenuListProps={{
                        'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                        },
                    }}
                >
                    <MenuItem
                        key='sign-out'
                        onClick={() => {
                            signout()
                            handleClose()
                        }}
                    >
                        Sign Out
                    </MenuItem>
                </Menu>
            </Box>
        </Box>
    )
}

export default Masthead
