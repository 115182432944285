import { Box, Typography } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from '../../app/useAuth'
import usePublish from '../../app/usePublish'
import SignerComponent from '../../components/Signer/SignerComponent'
import CreateBadgeInfoFields from './CreateBadgeInfoFields'
import useCreateBadgeValidation from './useCreateBadgeValidation'
import AboutKeyManagement from '../../components/Content/KeyManagement/AboutKeyManagement'
import Toggler from '../../components/Toggler/Toggler'
import {
    checkValidityOfPrivateKey,
    getNaddr,
    KeyMismatchError,
    MalformedKeyError,
    parseBadgeEvent,
} from '../../lib/utils'
import { BadgeData, BadgeImagesData, NostrReactEvent } from '../../app/types'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreatedBadges } from '../../app/CreatedBadgesContext'
import { UnsignedEvent as NostrUnsignedEvent } from 'nostr-tools'

export type BadgeInfoFields = {
    id: string
    name: string
    description: string
    hasWindowDotNostr: boolean
    privateKey: string
}

interface Props {
    isEditMode: boolean
}

const CreateBadgeForm: React.FC<Props> = ({ isEditMode }) => {
    const navigate = useNavigate()
    const { getPubkey, hasWindowDotNostr } = useAuth()
    const { badgeNaddr } = useParams()
    const { getBadgeByNaddr, rawBadgeList } = useCreatedBadges()
    const { validationSchema } = useCreateBadgeValidation()
    const initialValues: BadgeInfoFields = {
        id: '',
        name: '',
        description: '',
        hasWindowDotNostr,
        privateKey: '',
    }
    const [badgeValues, setBadgeValues] =
        useState<BadgeInfoFields>(initialValues)
    const { createBadgeEvent, signAndBroadcast, broadcastEvent } = usePublish()
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    const buttonLabel = isEditMode ? 'Update Badge' : 'Create Badge'

    const handleSubmit = (
        fields: BadgeInfoFields,
        { setFieldError }: FormikHelpers<BadgeInfoFields>
    ) => {
        // console.log('Create Badge Form: Submit called...')
        // console.log('Create Badge Form: Fields:', fields)
        if (!getPubkey()) {
            console.error('Create Badge Form: Submit: No pubkey available')
            return
        }

        let existingBadgeImages: BadgeImagesData = {
            image: '',
            xlThumb: '',
            lThumb: '',
            mThumb: '',
            sThumb: '',
            xsThumb: '',
        }

        if (!!badgeEvent) {
            existingBadgeImages = parseBadgeEvent(badgeEvent).images

            // console.error('Create Badge Form: Submit: Badge Event not found')
            // return
        }

        const unsignedBadgeEvent: NostrUnsignedEvent = createBadgeEvent(
            {
                author: getPubkey()!,
                identifier: fields.id,
                name: fields.name,
                description: fields.description,
            },
            existingBadgeImages
        )

        // console.debug(
        //     'Create Badge Form: Submit: Unsigned Badge Event:',
        //     unsignedBadgeEvent
        // )

        if (hasWindowDotNostr) {
            // console.debug(
            //     'Create Badge Form: Has Window.Nostr: Using signing extension...'
            // )
            let signedEvent

            try {
                // @ts-ignore: Property nostr does not exist
                window.nostr
                    .signEvent(unsignedBadgeEvent)
                    .then((signedEvent: NostrReactEvent) => {
                        // console.debug(
                        //     'Create Badge Form: Window.Nostr: Signed Event:',
                        //     signedEvent
                        // )
                        broadcastEvent(signedEvent)
                        proceedToNextScreen(
                            unsignedBadgeEvent.kind,
                            getPubkey()!,
                            fields.id
                        )
                    })
                    .catch((error: Error) => console.error(error))
                // console.debug('Create Badge Form: Signed Event:', signedEvent)
            } catch (e) {
                console.error(e)
            }
        } else {
            // console.debug(
            //     "Create Badge Form: Doesn't have Window.Nostr: Using naked private key..."
            // )

            try {
                const privateHex = checkValidityOfPrivateKey(
                    fields.privateKey,
                    getPubkey()!
                )
            } catch (e) {
                if (e instanceof MalformedKeyError) {
                    console.error('Create Badge Form:', e)
                    setFieldError(
                        'privateKey',
                        'This is not a valid Private Key.'
                    )
                }
                if (e instanceof KeyMismatchError) {
                    console.error('Create Badge Form:', e)
                    setFieldError(
                        'privateKey',
                        'Sorry. This Private Key cannot sign this event. It does not correspond to the Public Key.'
                    )
                }
            }

            signAndBroadcast(
                unsignedBadgeEvent as NostrReactEvent,
                fields.privateKey
            )
            proceedToNextScreen(
                unsignedBadgeEvent.kind,
                getPubkey()!,
                fields.id
            )
        }
    }

    const badgeEvent = useMemo(() => {
        // console.log(
        //     'Create Badge Form: Raw Badge List Length and Has Badge Naddr:',
        //     rawBadgeList.length,
        //     !!badgeNaddr
        // )

        if (!!badgeNaddr) {
            if (!!rawBadgeList.length) {
                return getBadgeByNaddr(badgeNaddr)
            } else {
                return null
            }
        } else {
            return null
        }
    }, [badgeNaddr, getBadgeByNaddr, rawBadgeList.length])

    useEffect(() => {
        if (!!badgeNaddr) {
            // console.log('Create Badge Form: Has Badge Naddr')
            if (badgeEvent) {
                // console.log('Create Badge Form: Badge Event:', badgeEvent)

                const badgeData = parseBadgeEvent(badgeEvent)
                // console.log(
                //     'Create Badge Form: Badge Name:',
                //     badgeData.info.name
                // )
                setBadgeValues({
                    ...initialValues,
                    id: badgeData.info.identifier,
                    name: badgeData.info.name,
                    description: badgeData.info.description,
                })
            }
        }
    }, [badgeNaddr, badgeEvent, setBadgeValues, parseBadgeEvent])

    useEffect(() => {
        // console.log('Create Badge Form: Badge Values:', badgeValues)
    }, [badgeValues])

    const handleActivateValidation = () => {
        setValidateOnChange(true)
    }

    const proceedToNextScreen = (
        kind: number,
        pubkey: string,
        identifier: string
    ) => {
        if (isEditMode) {
            navigate(`/badge/details/${badgeNaddr}`)
        } else {
            const newBadgeNaddr = getNaddr(kind, pubkey, identifier)
            navigate(`/badge/add-images/${newBadgeNaddr}`)
            // navigate(`/badge/add-images/${getNaddr(kind, pubkey, identifier)}`)
        }
    }

    return (
        <Formik
            initialValues={badgeValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnChange={validateOnChange}
            validateOnBlur={validateOnChange}
            onSubmit={handleSubmit}
        >
            {(errors) => {
                return (
                    <>
                        {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                        <Form id='create-badge-form'>
                            <Box sx={{ pb: '1rem' }}>
                                <CreateBadgeInfoFields
                                    onActivateValidation={
                                        handleActivateValidation
                                    }
                                    isEditMode={isEditMode}
                                />
                            </Box>
                            <SignerComponent
                                formName='create-badge-form'
                                standardButtonLabel={buttonLabel}
                                // extensionButtonLabel='Create Badge'
                                hasWindowDotNostr={hasWindowDotNostr}
                            />
                        </Form>
                        <Typography
                            variant='body1'
                            sx={{
                                py: '1rem',
                                textAlign: 'center',
                                fontStyle: 'italic',
                            }}
                        >
                            Next we will add images...
                        </Typography>

                        {!hasWindowDotNostr && (
                            <Box sx={{ py: '1rem' }}>
                                <Toggler title='How are private keys handled?'>
                                    <AboutKeyManagement />
                                </Toggler>
                            </Box>
                        )}
                    </>
                )
            }}
        </Formik>
    )
}

export default CreateBadgeForm
