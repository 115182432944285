import { Backdrop, CircularProgress } from '@mui/material'
import { useNostrEvents } from 'nostr-react'
import { nip19 } from 'nostr-tools'
import { AddressPointer } from 'nostr-tools/nip19'
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { sortBy } from 'sort-by-typescript'
import { hasTag } from '../lib/utils'
import { NostrReactEvent } from './types'
import { useAuth } from './useAuth'
import useBadge from './useBadge'

type EarnedBadgesContextState = {
    rawBadgeAwardsEvents: NostrReactEvent[]
    uniqueEarnedBadgesAddrList: string[]
    // uniqueEarnedBadgesList: NostrReactEvent[]
    isLoading: boolean
    isLoadingBadgeAwardsEvents: boolean
    isLoadingEarnedBadgesEvents: boolean
}

export const EarnedBadgesContext = createContext({} as EarnedBadgesContextState)

type Props = { children: React.ReactNode }

export const EarnedBadgesContextProvider: React.FC<Props> = ({ children }) => {
    const { getPubkey } = useAuth()
    const isFirstRender = useRef(true)
    const isFirstLoad = useRef(true)
    const [isLoading, setIsLoading] = useState(true)

    if (!getPubkey()) throw new Error('Use Earned Badges: Pubkey not found')
    const principalPubkey = getPubkey()!

    // console.log('Use Earned Badges: principalPubkey', principalPubkey)
    // console.log('Use Earned Badges: badgeNaddr', badgeNaddr)

    const {
        events: rawBadgeAwardsEvents,
        onEvent: onRawBadgeAwardsEvents,
        isLoading: isLoadingBadgeAwardsEvents,
    } = useNostrEvents({
        filter: {
            kinds: [8],
            '#p': [principalPubkey],
        },
    })

    const uniqueEarnedBadgesAddrList = useMemo(() => {
        return Object.keys(
            rawBadgeAwardsEvents
                .sort(sortBy('-created_at'))
                .reduce((map, item) => {
                    // const key = item.tags.filter(hasTag('a'))[0][1]
                    const key = item.tags[0][1]
                    // console.log('Use Earned Badges: Key:', key)
                    return {
                        ...map,
                        [`${key}`]: { key, created_at: item.created_at },
                    }
                }, {})
        ) as string[]
    }, [rawBadgeAwardsEvents])

    const {
        events: rawEarnedBadgesEvents,
        isLoading: isLoadingEarnedBadgesEvents,
    } = useNostrEvents({
        filter: {
            kinds: [3009],
            '#a': [...uniqueEarnedBadgesAddrList],
        },
    })

    const updateShowSpinner = () => {
        if (isLoading && isFirstLoad.current) {
            isFirstLoad.current = false
            // Give the component time to marshall the events into something it can use
            setTimeout(() => {
                setIsLoading(false)
            }, 700)
        }
    }

    useEffect(() => {
        // console.log(
        //     'Use Earned Badges: Load Users: Is Loading Events:',
        //     isLoadingEarnedBadgesEvents
        // )
        if (!isLoadingEarnedBadgesEvents) {
            updateShowSpinner()
        }
    }, [isLoadingEarnedBadgesEvents])

    const listenToEvents = useCallback(() => {
        onEvent: onRawBadgeAwardsEvents((event) => {
            // console.log('Use Earned Badges: Load Users: Event:', event)
            updateShowSpinner()
        })
    }, [onRawBadgeAwardsEvents, updateShowSpinner])

    useEffect(() => {
        if (isFirstRender.current) {
            // console.log(
            //     'Use Earned Badges: Load Users: Use Effect: Listen to Events called...'
            // )
            listenToEvents()
            isFirstRender.current = false
        }
    })

    const handleClose = () => {
        setIsLoading(false)
    }

    const value = useMemo(() => {
        return {
            rawBadgeAwardsEvents,
            uniqueEarnedBadgesAddrList,
            // uniqueEarnedBadgesList,
            isLoading,
            isLoadingBadgeAwardsEvents,
            isLoadingEarnedBadgesEvents,
        }
    }, [
        rawBadgeAwardsEvents,
        uniqueEarnedBadgesAddrList,
        // uniqueEarnedBadgesList,
        isLoading,
        isLoadingBadgeAwardsEvents,
        isLoadingEarnedBadgesEvents,
    ])

    return (
        <EarnedBadgesContext.Provider value={value}>
            {children}

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
                onClick={handleClose}
            >
                <CircularProgress />
            </Backdrop>
        </EarnedBadgesContext.Provider>
    )
}

export const useEarnedBadges = () => {
    return useContext(EarnedBadgesContext)
}
