import { useProfile } from 'nostr-react'
import { ProfileData } from '../../app/types'

type Props = {
    profileData: ProfileData
}

const DisplayName: React.FC<Props> = ({ profileData }) => {
    // console.log('Display Name: profileData:', profileData)

    const getBestDisplayName = () => {
        if (!!profileData?.display_name) {
            return profileData.display_name
        } else if (!!profileData?.name) {
            return profileData.name
        } else if (!!profileData?.nip05) {
            return profileData.nip05
        } else {
            return profileData?.npub
        }
    }

    return <>{getBestDisplayName()}</>
}

export default DisplayName
