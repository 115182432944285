import * as yup from 'yup'

const useAddImagesValidation = () => {
    // console.log('Badge Validation: Here...')
    const validationSchema = yup.object().shape({
        // image: yup.string().trim().required('A Full Size Image is required'),
        // xlThumb: yup
        //     .string()
        //     .trim()
        //     .required('An Extra Large Thumbnail is required'),
        // lThumb: yup.string().trim().required('A Large Thumbnail is required'),
        // mThumb: yup.string().trim().required('A Medium Thumbnail is required'),
        // sThumb: yup.string().trim().required('An Small Thumbnail is required'),
        // xsThumb: yup
        //     .string()
        //     .trim()
        //     .required('An Extra Small Thumbnail is required'),
        privateKey: yup
            .string()
            .nullable()
            .when('hasWindowDotNostr', {
                is: false,
                then: yup
                    .string()
                    .trim()
                    .required(
                        'Your Private Key (nsec or hex) is required to sign this event'
                    ),
                otherwise: yup.string().notRequired(),
            }),
    })

    return {
        validationSchema,
    }
}

export default useAddImagesValidation
