import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from './app/useAuth'

const LoginCheck = () => {
    const { isSignedIn } = useAuth()
    // console.debug('Auth Check: Is Logged In:', isSignedIn())
    // console.debug('Auth Check: Origin', window.location.origin)

    return isSignedIn() ? (
        <Outlet />
    ) : (
        <Navigate
            to='/'
            state={{
                targetUrl: window.location.href.replace(
                    window.location.origin,
                    ''
                ),
            }}
        />
    )
}

export default LoginCheck
