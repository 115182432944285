import { Box, Button } from '@mui/material'
import { Form, Formik, FormikHelpers } from 'formik'
import { useEffect, useMemo, useState } from 'react'
import { useAuth } from '../../app/useAuth'
import usePublish from '../../app/usePublish'
import SignerComponent from '../../components/Signer/SignerComponent'
import AddBadgeImagesFields from './AddBadgeImagesFields'
import useAddImagesValidation from './useAddImagesValidation'
import AboutKeyManagement from '../../components/Content/KeyManagement/AboutKeyManagement'
import Toggler from '../../components/Toggler/Toggler'
import {
    checkValidityOfPrivateKey,
    KeyMismatchError,
    MalformedKeyError,
    parseBadgeEvent,
} from '../../lib/utils'
import { NostrReactEvent } from '../../app/types'
import { useNavigate } from 'react-router-dom'
import { UnsignedEvent as NostrUnsignedEvent } from 'nostr-tools'
import { useCreatedBadges } from '../../app/CreatedBadgesContext'
import React from 'react'

export type BadgeImageFields = {
    image: string
    xlThumb: string
    lThumb: string
    mThumb: string
    sThumb: string
    xsThumb: string
    hasWindowDotNostr: boolean
    privateKey: string
}

interface Props {
    badgeNaddr: string
    stringifiedBadgeEvent: string
}

const AddBadgeImagesForm: React.FC<Props> = ({
    badgeNaddr,
    stringifiedBadgeEvent,
}) => {
    const navigate = useNavigate()
    const { getPubkey, hasWindowDotNostr } = useAuth()
    const { validationSchema } = useAddImagesValidation()
    const badgeData = parseBadgeEvent(JSON.parse(stringifiedBadgeEvent))
    const initialValues = {
        image: '',
        xlThumb: '',
        lThumb: '',
        mThumb: '',
        sThumb: '',
        xsThumb: '',
        hasWindowDotNostr,
        privateKey: '',
    }
    const [badgeValues, setBadgeValues] =
        useState<BadgeImageFields>(initialValues)
    const { createBadgeEvent, signAndBroadcast, broadcastEvent } = usePublish()
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)
    const { getBadgeByNaddr, rawBadgeList } = useCreatedBadges()

    const handleSubmit = (
        fields: BadgeImageFields,
        { setFieldError }: FormikHelpers<BadgeImageFields>
    ) => {
        // console.log('Add Badge Images Form: Submit called...')
        // console.log('Add Badge Images Form: Fields:', fields)
        if (!getPubkey()) {
            console.error('No pubkey available')
            return
        }

        // const existingBadgeData = parseBadgeEvent(existingBadgeEvent)

        const unsignedBadgeEvent: NostrUnsignedEvent = createBadgeEvent(
            badgeData.info,
            {
                image: fields.image,
                xlThumb: fields.xlThumb,
                lThumb: fields.lThumb,
                mThumb: fields.mThumb,
                sThumb: fields.sThumb,
                xsThumb: fields.xsThumb,
            }
        )

        if (hasWindowDotNostr) {
            // console.debug(
            //     'Add Badge Images Form: Has Window.Nostr: Using signing extension...'
            // )
            let signedEvent

            try {
                // @ts-ignore: Property nostr does not exist
                window.nostr
                    .signEvent(unsignedBadgeEvent)
                    .then((signedEvent: NostrReactEvent) => {
                        // console.debug(
                        //     'Add Badge Images Form: Window.Nostr: Signed Event:',
                        //     signedEvent
                        // )
                        broadcastEvent(signedEvent)
                        proceedToNextScreen()
                    })
                    .catch((error: Error) => console.error(error))
                // console.debug(
                //     'Add Badge Images Form: Signed Event:',
                //     signedEvent
                // )
            } catch (e) {
                console.error(e)
            }
        } else {
            // console.debug(
            //     "Add Badge Images Form: Doesn't have Window.Nostr: Using naked private key..."
            // )

            try {
                const privateHex = checkValidityOfPrivateKey(
                    fields.privateKey,
                    unsignedBadgeEvent.pubkey
                )
            } catch (e) {
                if (e instanceof MalformedKeyError) {
                    console.error('Add Badge Images Form:', e)
                    setFieldError(
                        'privateKey',
                        'This is not a valid Private Key.'
                    )
                }
                if (e instanceof KeyMismatchError) {
                    console.error('Add Badge Images Form:', e)
                    setFieldError(
                        'privateKey',
                        'Sorry. This Private Key cannot sign this event. It does not correspond to the Public Key.'
                    )
                }
            }

            signAndBroadcast(
                unsignedBadgeEvent as NostrReactEvent,
                fields.privateKey
            )
            proceedToNextScreen()
        }
    }

    useEffect(() => {
        if (!!badgeNaddr) {
            // console.log('Add Badge Images Form: Has Badge Naddr')
            if (!!badgeData) {
                // console.log('Add Badge Images Form: Badge Event:', badgeData)

                // const badgeData = parseBadgeEvent(badgeEvent)
                // console.log(
                //     'Add Badge Images Form: Badge Name:',
                //     badgeData.info.name
                // )
                setBadgeValues({
                    ...initialValues,
                    image: badgeData.images.image!,
                    xlThumb: badgeData.images.xlThumb!,
                    lThumb: badgeData.images.lThumb!,
                    mThumb: badgeData.images.mThumb!,
                    sThumb: badgeData.images.sThumb!,
                    xsThumb: badgeData.images.xsThumb!,
                })
            }
        }
    }, [badgeNaddr, badgeData, setBadgeValues, parseBadgeEvent])

    // useEffect(() => {
    //     console.log('Add Badge Images Form: Badge Values:', badgeValues)
    // }, [badgeValues])

    const handleActivateValidation = () => {
        setValidateOnChange(true)
    }

    const proceedToNextScreen = () => {
        navigate(`/badge/details/${badgeNaddr}`)
    }

    return (
        <Formik
            initialValues={badgeValues}
            enableReinitialize={true}
            validationSchema={validationSchema}
            validateOnChange={validateOnChange}
            validateOnBlur={validateOnChange}
            onSubmit={handleSubmit}
        >
            {() => {
                return (
                    <>
                        <Form id='add-images-form'>
                            <Box sx={{ pb: '1rem' }}>
                                <AddBadgeImagesFields
                                    onActivateValidation={
                                        handleActivateValidation
                                    }
                                />
                            </Box>
                            <SignerComponent
                                formName='add-images-form'
                                standardButtonLabel='Add Images'
                                // extensionButtonLabel='Create Badge'
                                hasWindowDotNostr={hasWindowDotNostr}
                            />
                        </Form>
                        <Box
                            display='flex'
                            justifyContent='center'
                            sx={{ mt: '1rem' }}
                        >
                            <Button
                                onClick={() =>
                                    navigate(`/badge/details/${badgeNaddr}`)
                                }
                                variant='text'
                                sx={{
                                    color: '#777',
                                    textTransform: 'none',
                                    fontWeight: 'fontWeightRegular',
                                }}
                            >
                                Cancel
                            </Button>
                        </Box>

                        {!hasWindowDotNostr && (
                            <Box sx={{ py: '1rem' }}>
                                <Toggler title='How are private keys handled?'>
                                    <AboutKeyManagement />
                                </Toggler>
                            </Box>
                        )}
                    </>
                )
            }}
        </Formik>
    )
}

export default React.memo(AddBadgeImagesForm)
