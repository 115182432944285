import { dateToUnix, useNostr } from 'nostr-react'
import {
    UnsignedEvent as NostrUnsignedEvent,
    getEventHash,
    signEvent,
    nip19,
} from 'nostr-tools'
import { BadgeImagesData, BadgeInfoData, NostrReactEvent } from './types'
import appKeys from './appKeys'
import { AddressPointer } from 'nostr-tools/nip19'

const usePublish = () => {
    const { publish } = useNostr()

    const createBadgeEvent = (
        badgeInfoData: BadgeInfoData,
        badgeImagesData?: BadgeImagesData
    ) => {
        // console.log('Publish: Public Hex:', badgeInfoData.author)

        const badgeEvent: NostrUnsignedEvent = {
            content: '',
            kind: 30009,
            tags: [
                ['d', badgeInfoData.identifier],
                ['name', badgeInfoData.name],
                ['description', badgeInfoData.description],
            ],
            created_at: dateToUnix(),
            pubkey: badgeInfoData.author,
        }

        if (!!badgeImagesData) {
            const { image, xlThumb, lThumb, mThumb, sThumb, xsThumb } =
                badgeImagesData
            const imageTags: string[][] = [
                ['image', image ? image : '', '1024x1024'],
                ['thumb', xlThumb ? xlThumb : '', '512x512'],
                ['thumb', lThumb ? lThumb : '', '256x256'],
                ['thumb', mThumb ? mThumb : '', '64x64'],
                ['thumb', sThumb ? sThumb : '', '32x32'],
                ['thumb', xsThumb ? xsThumb : '', '16x16'],
            ]

            badgeEvent.tags = [...badgeEvent.tags, ...imageTags]
        }

        return badgeEvent
    }

    const createBadgeAwardEvent = (
        badgeNaddr: string,
        bestowerPubkey: string,
        recipientPubkey: string,
        relay: string = ''
    ) => {
        const addressPointer = nip19.decode(badgeNaddr).data as AddressPointer
        return {
            content: '',
            kind: 8,
            tags: [
                [
                    'a',
                    `${addressPointer.kind}:${addressPointer.pubkey}:${addressPointer.identifier}`,
                ],
                ['p', recipientPubkey, relay],
            ],
            created_at: dateToUnix(),
            pubkey: bestowerPubkey,
        }
    }

    const createNoticeOfBadgeAward = (
        badgeName: string,
        bestowerPubkey: string,
        recipientPubkey: string,
        badgeThumb: string,
        badgeNaddr: string
    ) => {
        const addressPointer = nip19.decode(badgeNaddr).data as AddressPointer
        return {
            content: `#[0] awarded #[1] the ${badgeName} badge on https://NostrBadges.com \n
            ${badgeThumb}`,
            kind: 1,
            tags: [
                ['p', bestowerPubkey],
                ['p', recipientPubkey],
                [
                    'a',
                    `${addressPointer.kind}:${addressPointer.pubkey}:${addressPointer.identifier}`,
                ],
            ],
            created_at: dateToUnix(),
            pubkey: appKeys.publicHex,
        }
    }

    const signAndBroadcast = (event: NostrReactEvent, privateHex?: string) => {
        event.id = getEventHash(event)
        event.sig = signEvent(event, privateHex!)

        // console.log('Publish: Sign and Broadcast: Event:', event)

        broadcastEvent(event)
    }

    const broadcastEvent = (event: NostrReactEvent) => {
        publish(event)
    }

    return {
        createBadgeEvent,
        createBadgeAwardEvent,
        createNoticeOfBadgeAward,
        signAndBroadcast,
        broadcastEvent,
    }
}

export default usePublish
