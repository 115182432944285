import React, { useEffect } from 'react'
import { useNostrEvents } from 'nostr-react'
import UserCardVertical from './UserCardVertical'

type Props = {
    pubkey: string
}

const UserCardContainer: React.FC<Props> = ({ pubkey }) => {
    const { events: userProfileEvents } = useNostrEvents({
        filter: {
            authors: [pubkey!],
            since: 0,
            kinds: [0],
            limit: 1,
        },
    })

    console.log('User Card Container: Pubkey...', pubkey)

    // useEffect(() => {
    //     if (userProfileEvents.length > 0) {
    //         console.log(
    //             'User Card Container: User Content:',
    //             userProfileEvents[0].content
    //         )
    //     }
    // }, [userProfileEvents])

    return (
        <>
            {userProfileEvents.length > 0 && (
                <UserCardVertical
                    // profileData={
                    //     parseContent(
                    //         userProfileEvents[0].content
                    //     ) as ProfileData
                    // }
                    profileContent={userProfileEvents[0].content}
                    pubkey={pubkey!}
                />
            )}
        </>
    )
}

export default React.memo(UserCardContainer)
