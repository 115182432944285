import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import ImageIcon from '@mui/icons-material/Image'
import { parseBadgeEvent } from '../../lib/utils'

interface Props {
    stringifiedBadgeEvent: string
}

const BadgeListCard: React.FC<Props> = ({ stringifiedBadgeEvent }) => {
    const navigate = useNavigate()
    const badge = parseBadgeEvent(JSON.parse(stringifiedBadgeEvent))

    return (
        <Box
            display='flex'
            flexDirection='column'
            sx={{
                my: '1rem',
                p: '.5rem',
                border: '1px solid lightgray',
                borderRadius: '.25rem',
            }}
        >
            <Box
                key={`badge-${badge?.info.identifier}`}
                display='flex'
                alignContent='flex-start'
            >
                {!!badge && !!badge.images.lThumb && (
                    <Box
                        component='img'
                        src={badge.images.lThumb}
                        sx={{
                            width: '128px',
                            height: '128px',
                            borderRadius: '.25rem',
                            backgroundColor: 'lightgray',
                        }}
                    />
                )}
                {!badge ||
                    (!badge.images.mThumb && (
                        <ImageIcon
                            accentHeight='0'
                            sx={{
                                width: '128px',
                                height: '128px',
                                borderRadius: '.25rem',
                                color: 'lightgray',
                            }}
                        />
                    ))}
                <Box
                    display='flex'
                    flexDirection='column'
                    flexGrow={1}
                    sx={{
                        pl: '1rem',
                        // border: '1px solid gray',
                    }}
                >
                    <Typography
                        variant='h6'
                        fontWeight='fontWeightRegular'
                        color='text.primary'
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                            width: 'inherit',
                            // width: '180px',
                            // width: '240px',
                            // height: '1.25rem',
                            // display: 'inline-block',
                            // border: '1px solid gray',
                            display: '-webkit-box',
                            '-webkit-line-clamp': '1',
                            '-webkit-box-orient': 'vertical',

                            // overflow: 'hidden',
                            // whiteSpace: 'nowrap',
                            // textOverflow: 'ellipsis',
                            // width: 'inherit',
                            // // width: '310px',
                            // display: 'inline-block',
                            // // border: '1px solid gray',
                        }}
                    >
                        {badge?.info.name}
                    </Typography>
                    <Typography
                        variant='body1'
                        color='text.primary'
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                            width: 'inherit',
                            // width: '180px',
                            // width: '240px',
                            height: '3.25rem',
                            // display: 'inline-block',
                            // border: '1px solid gray',
                            display: '-webkit-box',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                        }}
                    >
                        {badge?.info.description}
                    </Typography>
                    <Box
                        display='flex'
                        justifyContent='space-evenly'
                        sx={{ border: '0px solid gray', mt: '.5rem' }}
                    >
                        <Button
                            onClick={() =>
                                navigate(`/badge/awards/${badge.naddr}`)
                            }
                            variant='contained'
                            size='small'
                            sx={{ mr: '.5rem', flexGrow: '1' }}
                        >
                            Awards
                        </Button>
                        <Button
                            onClick={() =>
                                navigate(`/badge/details/${badge.naddr}`)
                            }
                            variant='contained'
                            size='small'
                            sx={{ ml: '.5rem', flexGrow: '1' }}
                        >
                            Details
                        </Button>
                    </Box>
                </Box>
            </Box>
            {/* <Typography variant='body2'>{badge?.info.identifier}</Typography> */}
        </Box>
    )
}

export default React.memo(BadgeListCard)
