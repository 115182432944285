import * as yup from 'yup'

const useSignInValidation = () => {
    const validationSchema = yup.object().shape({
        // hasWindowDotNostr: yup.boolean().isTrue('Has window.nostr'),
        username: yup
            .string()
            .nullable()
            .when('hasWindowDotNostr', {
                is: false,
                then: yup
                    .string()
                    .trim()
                    .required(
                        'A Username (NIP-05) or Public Key (npub or hex) is required.'
                    ),
                otherwise: yup.string().notRequired(),
            }),
    })

    return {
        validationSchema,
    }
}

export default useSignInValidation
