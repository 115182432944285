import { Badge, Box, Paper, Typography } from '@mui/material'
import React from 'react'
import RecipientsList from './RecipientsList'
import { useAwardedUsers } from './useAwardedUsers'

interface Props {
    badgeNaddr: string
}

const AwardedUsers: React.FC<Props> = ({ badgeNaddr }) => {
    const { recipientsList } = useAwardedUsers()

    return (
        <>
            {recipientsList.length > 0 && (
                <>
                    <Box
                        display='flex'
                        justifyContent='flex-start'
                        alignItems='baseline'
                    >
                        <Typography
                            // component='span'
                            variant='h6'
                            fontWeight='fontWeightRegular'
                        >
                            {recipientsList.length} Badge Recipients
                        </Typography>
                    </Box>
                    <Box
                        display='flex'
                        flexDirection='row'
                        justifyContent='space-evenly'
                        alignItems='flex-start'
                        flexWrap='wrap'
                        gap={2}
                        sx={{
                            px: '1rem',
                            border: '0px solid gray',
                        }}
                    >
                        <RecipientsList recipientsList={recipientsList} />
                    </Box>
                </>
            )}
        </>
        // <>
        //     {!!recipientList && (
        //         <>
        //             {recipientList.map((awardEvent, i) => {
        //                 // return <>{awardEvent.tags[1][1]?.substring(0, 7)}</>
        //                 return <>{awardEvent.tags[1][1]?.substring(0, 7)}</>
        //             })}
        //         </>
        //     )}
        // </>
    )
}

export default React.memo(AwardedUsers)
