import React from 'react'
import { Box, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import ImageIcon from '@mui/icons-material/Image'
import { parseBadgeEvent } from '../../lib/utils'

interface Props {
    stringifiedBadge: string
}

const BadgeDetailsImages: React.FC<Props> = ({ stringifiedBadge }) => {
    const badge = parseBadgeEvent(JSON.parse(stringifiedBadge))

    return (
        <Box
            display='flex'
            flexDirection='column'
            flexGrow={1}
            sx={
                {
                    // pl: '1rem',
                    // border: '1px solid gray',
                }
            }
        >
            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>
                    Primary Image (1024 x 1024)
                </Typography>
                <Typography
                    variant='body1'
                    fontWeight='fontWeightRegular'
                    color='text.primary'
                    sx={{
                        width: '410px',
                        wordBreak: 'break-all',
                    }}
                >
                    {!!badge?.images.image
                        ? badge.images.image
                        : 'No image provided'}
                </Typography>
            </Box>

            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>
                    XL Thumbnail (512 x 512)
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        width: '410px',
                        wordBreak: 'break-all',
                    }}
                >
                    {!!badge?.images.xlThumb
                        ? badge.images.xlThumb
                        : 'No image provided'}
                </Typography>
            </Box>

            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>
                    L Thumbnail (256 x 256)
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        width: '410px',
                        wordBreak: 'break-all',
                    }}
                >
                    {!!badge?.images.lThumb
                        ? badge.images.lThumb
                        : 'No image provided'}
                </Typography>
            </Box>

            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>
                    M Thumbnail (64 x 64)
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        width: '410px',
                        wordBreak: 'break-all',
                    }}
                >
                    {!!badge?.images.mThumb
                        ? badge.images.mThumb
                        : 'No image provided'}
                </Typography>
            </Box>

            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>
                    S Thumbnail (32 x 32)
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        width: '410px',
                        wordBreak: 'break-all',
                    }}
                >
                    {!!badge?.images.sThumb
                        ? badge.images.sThumb
                        : 'No image provided'}
                </Typography>
            </Box>

            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>
                    XS Thumbnail (16 x 16)
                </Typography>
                <Typography
                    variant='body1'
                    sx={{
                        width: '410px',
                        wordBreak: 'break-all',
                    }}
                >
                    {!!badge?.images.xsThumb
                        ? badge.images.xsThumb
                        : 'No image provided'}
                </Typography>
            </Box>
        </Box>
    )
}

export default React.memo(BadgeDetailsImages)
