import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import ImageIcon from '@mui/icons-material/Image'
import { parseBadgeEvent } from '../../lib/utils'
import { format, formatDistance } from 'date-fns'
import { dateToUnix } from 'nostr-react'

interface Props {
    stringifiedBadge: string
}

const BadgeDetailsInfo: React.FC<Props> = ({ stringifiedBadge }) => {
    const currentDate = useRef(dateToUnix(new Date()))
    const badge = parseBadgeEvent(JSON.parse(stringifiedBadge))

    return (
        <Box
            display='flex'
            flexDirection='column'
            flexGrow={1}
            sx={
                {
                    // pl: '1rem',
                    // border: '1px solid gray',
                }
            }
        >
            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>Name</Typography>
                <Typography
                    variant='body1'
                    fontWeight='fontWeightRegular'
                    color='text.primary'
                    sx={{}}
                >
                    {badge?.info.name}
                </Typography>
            </Box>

            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>Description</Typography>
                <Typography variant='body1' color='text.primary' sx={{}}>
                    {badge?.info.description}
                </Typography>
            </Box>

            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>Identifier</Typography>
                <Typography variant='body1' color='text.primary' sx={{}}>
                    {badge?.info.identifier}
                </Typography>
            </Box>

            <Box sx={{ pb: '1rem' }}>
                <Typography variant='subtitle2'>Date Modified</Typography>
                <Typography variant='body1' color='text.primary' sx={{}}>
                    {format(
                        new Date(badge?.createdDate! * 1000),
                        'MMMM d, YYY h:mm bbbb'
                    )}
                </Typography>
                <Typography variant='body2'>
                    (
                    {formatDistance(
                        new Date(badge?.createdDate! * 1000),
                        new Date(currentDate.current * 1000),
                        {
                            addSuffix: true,
                        }
                    )}
                    )
                </Typography>
            </Box>
        </Box>
    )
}

export default React.memo(BadgeDetailsInfo)
