import { Box, Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useAuth } from '../../app/useAuth'
import Footer from './Footer'
import Masthead from './Masthead'
import PublicMasthead from './PublicMasthead'

const Layout = () => {
    const { isSignedIn } = useAuth()

    return (
        <Container
            maxWidth='xs'
            disableGutters={true}
            sx={{
                p: isSignedIn() ? 0 : '1rem',
                // width: { xs: '375px', sm: '420px' },
                minHeight: '100%',
                borderLeft: '1px solid #e6e7eb',
                borderRight: '1px solid #e6e7eb',
                backgroundColor: '#fff',
            }}
        >
            {isSignedIn() ? (
                <Box
                    display='flex'
                    alignItems='stretch'
                    sx={{
                        // position: 'fixed',
                        // border: '1px solid green',
                        // flexGrow: 1,
                        width: 'inherit',
                    }}
                >
                    <Masthead />
                </Box>
            ) : (
                <PublicMasthead />
            )}
            <Box
                sx={{
                    p: isSignedIn() ? '1rem' : 0,
                    pt: isSignedIn() ? '7rem' : 0,
                }}
            >
                <Outlet />
                <Footer />
            </Box>
        </Container>
    )
}

export default Layout
