import * as yup from 'yup'

const useCreateBadgeValidation = () => {
    const validationSchema = yup.object().shape({
        id: yup.string().trim().required('An Id is required'),
        name: yup.string().trim().required('A Name is required'),
        description: yup.string().trim().required('An Description is required'),
        privateKey: yup
            .string()
            .nullable()
            .when('hasWindowDotNostr', {
                is: false,
                then: yup
                    .string()
                    .trim()
                    .required(
                        'Your Private Key (nsec or hex) is required to sign this event'
                    ),
                otherwise: yup.string().notRequired(),
            }),
    })

    return {
        validationSchema,
    }
}

export default useCreateBadgeValidation
