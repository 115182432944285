import { Box, Typography, Divider, Button, Link } from '@mui/material'
import { useEffect, useMemo } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreatedBadges } from '../../app/CreatedBadgesContext'
import BadgeDetailsInfo from './BadgeDetailsInfo'
import BadgeDetailsImages from './BadgeDetailsImages'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const BadgeDetails = () => {
    const navigate = useNavigate()
    const { badgeNaddr } = useParams()
    const { isLoading, getBadgeByNaddr, rawBadgeList } = useCreatedBadges()

    if (!badgeNaddr) throw new Error('Badge Details: Badge naddr not found')

    const badgeEvent = useMemo(() => {
        // console.log(
        //     'Badge Details: Raw Badge List Length:',
        //     rawBadgeList.length
        // )
        if (!!rawBadgeList.length) {
            return getBadgeByNaddr(badgeNaddr)
        } else {
            return null
        }
    }, [badgeNaddr, getBadgeByNaddr, rawBadgeList.length])

    // const badge: BadgeData | null = useMemo(() => {
    //     return !!badgeEvent ? parseBadgeEvent(badgeEvent) : null
    // }, [badgeEvent])

    // useEffect(() => {
    //     console.log('Badge Details: Badge Event:', badgeEvent)
    // }, [badgeEvent])

    return (
        <>
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    mt: '0rem',
                }}
            >
                <Link
                    alignSelf='start'
                    component='button'
                    variant='h6'
                    underline='none'
                    onClick={() => navigate(`/badge/creations`)}
                    style={{ display: 'flex' }}
                >
                    <ChevronLeftIcon
                        color='primary'
                        style={{
                            fontSize: '2rem',
                        }}
                    />
                </Link>
                <Typography variant='h6' sx={{ pb: '.3rem' }}>
                    Badge Details
                </Typography>
                <Box sx={{ width: '32px', height: '32px' }}></Box>
            </Box>
            <Divider sx={{ my: '.5rem' }} />

            <Box
                display='flex'
                justifyContent='space-between'
                sx={{ my: '1rem' }}
            >
                <Typography variant='h5' fontWeight='fontWeightRegular'>
                    Information
                </Typography>
                <Button
                    onClick={() => navigate(`/badge/create/${badgeNaddr}`)}
                    variant='text'
                    size='medium'
                    startIcon={<EditIcon />}
                >
                    Edit
                </Button>
            </Box>

            {!isLoading && !!badgeEvent && (
                <Box key={`badge-${badgeEvent.id}`}>
                    <BadgeDetailsInfo
                        stringifiedBadge={JSON.stringify(badgeEvent)}
                    />
                </Box>
            )}

            <Box
                display='flex'
                justifyContent='space-between'
                sx={{ mt: '.5rem', mb: '1rem' }}
            >
                <Typography variant='h5' fontWeight='fontWeightRegular'>
                    Images
                </Typography>
                <Button
                    onClick={() => navigate(`/badge/add-images/${badgeNaddr}`)}
                    variant='text'
                    size='medium'
                    startIcon={<EditIcon />}
                >
                    Edit
                </Button>
            </Box>
            <Box
                sx={{
                    width: '410px',
                    wordBreak: 'break-all',
                }}
            >
                {!isLoading && !!badgeEvent && (
                    <Box key={`badge-${badgeEvent.id}`} sx={{ mt: '1rem' }}>
                        <BadgeDetailsImages
                            stringifiedBadge={JSON.stringify(badgeEvent)}
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}

export default BadgeDetails
