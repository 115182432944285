import { getPublicKey, nip19 } from 'nostr-tools'
import { BadgeData, NostrReactEvent, ProfileData } from '../app/types'
import { Event as NostrEvent } from 'nostr-tools'
import { AddressPointer } from 'nostr-tools/nip19'

// export const emailRegEx =
//     /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const getKeyFormat = (value: string): 'nsec' | 'hex' | undefined => {
    if (value.match(/[a-f0-9]{64}/)) {
        return 'hex'
    } else if (value.match(/nsec1\w+/)) {
        return 'nsec'
    } else {
        return undefined
    }
}

export const checkValidityOfPrivateKey = (
    privateKey: string,
    publicKey: string
) => {
    let privateHex: string = ''

    // Determine the key format (nsec or hex)
    // Validate the key
    // and convert to hex if necessary
    try {
        switch (getKeyFormat(privateKey)) {
            case 'nsec':
                // Verify that the key can be decoded
                // Throws an error otherwise
                privateHex = nip19.decode(privateKey).data as string
                break
            case 'hex':
                // Verify that the key can be encoded
                nip19.nsecEncode(privateKey)
                privateHex = privateKey
                break
            default:
                throw new MalformedKeyError()
        }
    } catch (e) {
        console.error(e)
        throw new MalformedKeyError()
    }

    // Make sure the Private Key is valid
    try {
        if (getPublicKey(privateHex) !== publicKey) {
            throw new KeyMismatchError()
        }
    } catch (e) {
        console.error(e)
        throw new KeyMismatchError()
    }

    return privateHex
}

export const extractPublicKey = (skNsec: string) =>
    getPublicKey(nip19.decode(skNsec).data as string)

export const parseContent = (content: string): ProfileData | undefined => {
    try {
        return JSON.parse(content) as ProfileData
    } catch (err) {
        console.log(content)
        console.error(err)
    }
}

export const getUsername = (userProfile: ProfileData) => {
    if (!!userProfile.nip05) {
        if (userProfile.nip05.startsWith('_')) {
            const tempNip05 = userProfile.nip05
            return tempNip05.substring(1)
        }
        return userProfile.nip05
    } else if (!!userProfile.username) {
        return userProfile.username.startsWith('@')
            ? userProfile.username
            : `@${userProfile.username}`
    }
}

export const uniqBy = <T>(arr: T[], key: keyof T): T[] => {
    return Object.values(
        arr.reduce(
            (map, item) => ({
                ...map,
                [`${item[key]}`]: item,
            }),
            {}
        )
    )
}

export const getNaddr = (
    kind: number,
    pubkey: string,
    identifier: string,
    relays: string[] = ['']
) => {
    return nip19.naddrEncode({
        pubkey,
        relays,
        kind,
        identifier,
    } as AddressPointer)
}

export const getAddressPointer = (naddr: string) => {
    return nip19.decode(naddr).data as AddressPointer
}

export const parseBadgeEvent = (badgeEvent: NostrReactEvent): BadgeData => {
    const newBadge: BadgeData = {
        naddr: '',
        createdDate: badgeEvent.created_at,
        info: {
            author: badgeEvent.pubkey,
            identifier: badgeEvent.tags.filter((tag) => tag[0] === 'd')[0][1],
            name: badgeEvent.tags.filter((tag) => tag[0] === 'name')[0][1],
            description: badgeEvent.tags.filter(
                (tag) => tag[0] === 'description'
            )[0][1],
        },
        images: {
            image: badgeEvent.tags.reduce(
                (acc, tag) => (tag[0] === 'image' ? tag[1] : acc),
                ''
            ),
            xlThumb: badgeEvent.tags.reduce(
                (acc, tag) =>
                    tag[0] === 'thumb' && tag[2] === '512x512' ? tag[1] : acc,
                ''
            ),
            lThumb: badgeEvent.tags.reduce(
                (acc, tag) =>
                    tag[0] === 'thumb' && tag[2] === '256x256' ? tag[1] : acc,
                ''
            ),
            mThumb: badgeEvent.tags.reduce(
                (acc, tag) =>
                    tag[0] === 'thumb' && tag[2] === '64x64' ? tag[1] : acc,
                ''
            ),
            sThumb: badgeEvent.tags.reduce(
                (acc, tag) =>
                    tag[0] === 'thumb' && tag[2] === '32x32' ? tag[1] : acc,
                ''
            ),
            xsThumb: badgeEvent.tags.reduce(
                (acc, tag) =>
                    tag[0] === 'thumb' && tag[2] === '16x16' ? tag[1] : acc,
                ''
            ),
            thumb: badgeEvent.tags.reduce(
                (acc, tag) => (tag[0] === 'thumb' && !tag[2] ? tag[1] : acc),
                ''
            ),
        },
    }

    // const badgeImageArr = badgeEvent.tags.filter((tag) => tag[0] === 'image')
    // const badgeImage = (!!badgeImageArr) ? badgeImageArr[0][1] : ''

    newBadge.naddr = getNaddr(
        badgeEvent.kind,
        badgeEvent.pubkey,
        newBadge.info.identifier
    )

    return newBadge
}

export const hasTag = (hashTag: string) => {
    return (tag: string[]) => tag[0] === hashTag
}

export class KeyMismatchError extends Error {
    constructor(
        message = 'There is a mismatch between the private and public key'
    ) {
        super()

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, KeyMismatchError)
        }
    }
}

export class MalformedKeyError extends Error {
    constructor(message = 'The private key is malformed') {
        super()

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, KeyMismatchError)
        }
    }
}
