const TermsOfUse = () => {
    return (
        <>
            {' '}
            <ul style={{ paddingLeft: '15px' }}>
                <li>
                    This is a pre-beta version of Verified Human. It is under
                    active development. No warranty is made regarding its
                    fitness for any purpose. Use at your own risk.
                </li>
                <li>
                    Access is granted as part of a pre-release, limited access
                    program. The availability of the application and access to
                    it are not guaranteed.
                    {/* Your access may be revoked at any time without cause,
                warning, or explanation. */}
                </li>
                <li>
                    Data (Notes) generated by the application is stored on
                    public relays. It may be deleted at any time or remain
                    publicly accessible indefinitely.
                </li>
                {/* <li>
                    All feedback provided by you to the developers is offered
                    freely without expectation of compensation.
                </li> */}
                {/* <li>
                Paperlight Studio retains all rights to intellectual property
                protections.
            </li> */}
                {/* <li>
                    The developers and Paperlight Studio LLC are not liable for
                    any damages incurred by you or any other party from use of
                    this application.
                </li> */}
                {/* <li>These terms will likely change.</li> */}
            </ul>
        </>
    )
}

export default TermsOfUse
