import { Typography } from '@mui/material'
import { Box } from '@mui/system'

const Footer = () => (
    <Box
        sx={{
            mt: '2.5rem',
            pt: '1rem',
            pb: '.5rem',
            pl: '1rem',
            textAlign: 'center',
            borderTop: '1px solid #e6e7eb',
        }}
    >
        <Typography variant='body2' fontWeight='fontWeightLight'>
            &copy; 2023 Paperlight Studio LLC
        </Typography>
        <Typography variant='body2' fontWeight='fontWeightLight'>
            nostr:{' '}
            <a
                href='https://snort.social/p/npub1avar8zfzwswhq9xh3t0gc5l38pf5kesrkqe2s2ymrnymv3ddl2ps38t7kg'
                target='_blank'
                rel='noreferrer'
                style={{
                    color: '#777',
                    textDecoration: 'none',
                    textDecorationColor: 'lightgray',
                }}
            >
                jason@paperlight.studio
            </a>
        </Typography>
    </Box>
)

export default Footer
