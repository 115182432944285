import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { NostrProvider } from 'nostr-react'
import { BrowserRouter } from 'react-router-dom'
import { relayUrls } from './app/relays'
import theme from './app/theme'
import AppRouter from './AppRouter'
import { AuthContextProvider } from './app/useAuth'

const App = () => (
    <ThemeProvider theme={theme}>
        <NostrProvider relayUrls={relayUrls} debug={false}>
            <BrowserRouter>
                <AuthContextProvider>
                    <CssBaseline />
                    <AppRouter />
                </AuthContextProvider>
            </BrowserRouter>
        </NostrProvider>
    </ThemeProvider>
)

export default App
