import { Box, Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { CreatedBadgesContextProvider } from '../../../app/CreatedBadgesContext'
import { useAuth } from '../../../app/useAuth'
import Footer from '../../../components/Layout/Footer'
import Layout from '../../../components/Layout/Layout'
import Masthead from '../../../components/Layout/Masthead'
import { EarnedBadgesContextProvider } from '../../../app/useEarnedBadges'

const EarnedBadgesWrapper = () => {
    return (
        <EarnedBadgesContextProvider>
            <Layout />
        </EarnedBadgesContextProvider>
    )
}

export default EarnedBadgesWrapper
