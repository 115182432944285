import { Box, Typography, Button, Divider } from '@mui/material'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { useCreatedBadges } from '../../app/CreatedBadgesContext'
import { sortBy } from 'sort-by-typescript'
import { NostrReactEvent } from '../../app/types'
import BadgeListCard from '../../components/Cards/BadgeListCard'

const BadgeCreations = () => {
    const { isLoading, rawBadgeList } = useCreatedBadges()
    const navigate = useNavigate()

    const uniqueBadgeList: NostrReactEvent[] = useMemo(() => {
        return Object.values(
            rawBadgeList.sort(sortBy('created_at')).reduce((map, item) => {
                const key = item.tags[0][1]
                return {
                    ...map,
                    [`${key}`]: item,
                }
            }, {})
        ).sort(sortBy('-created_at')) as NostrReactEvent[]
    }, [rawBadgeList])

    // useEffect(() => {
    //     console.log('Badge Creations: Unique Badge List:', uniqueBadgeList)
    // }, [uniqueBadgeList])

    return (
        <>
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    mt: '0rem',
                    // pb: '.75rem',
                    // border: '1px solid gray',
                }}
            >
                <Typography variant='h6' sx={{ pb: '.3rem' }}>
                    Badges I've Created
                </Typography>
                {!isLoading && uniqueBadgeList.length > 0 && (
                    <Button
                        variant='text'
                        size='medium'
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => navigate('/badge/create')}
                    >
                        Create
                    </Button>
                )}
            </Box>
            <Divider sx={{ mt: '.5rem' }} />
            <Box
                sx={{
                    // mt: '2rem',
                    minHeight: '20rem',
                }}
            >
                {!isLoading &&
                    !!uniqueBadgeList &&
                    uniqueBadgeList.length === 0 && (
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            gap={4}
                            sx={{
                                mt: '1rem',
                                p: '1rem',
                                pb: '1.5rem',
                                border: '1px solid lightgray',
                                borderRadius: '.25rem',
                            }}
                        >
                            <Typography
                                variant='h6'
                                fontWeight='fontWeightRegular'
                            >
                                You haven't created any badges yet.
                            </Typography>
                            <Button
                                variant='contained'
                                size='large'
                                startIcon={<AddCircleOutlineIcon />}
                                onClick={() => navigate('/badge/create')}
                            >
                                Create One Now
                            </Button>
                        </Box>
                    )}
                {!isLoading && uniqueBadgeList.length > 0 && (
                    <>
                        {/* <Box>{uniqueBadgeList.length}</Box> */}
                        {uniqueBadgeList.map((badge, i) => {
                            return (
                                <Box key={`badge-${badge.tags[0][1]}`}>
                                    {/* {badge.tags[0][1]} */}
                                    <BadgeListCard
                                        stringifiedBadgeEvent={JSON.stringify(
                                            badge
                                        )}
                                    />
                                </Box>
                            )
                        })}
                    </>
                )}
            </Box>
        </>
    )
}

export default BadgeCreations
