import { Box, Avatar, Typography } from '@mui/material'
import { useNostrEvents } from 'nostr-react'
import React, { useCallback, useEffect } from 'react'
import { sortBy } from 'sort-by-typescript'
import { ProfileData } from '../../app/types'
import { parseContent, getUsername } from '../../lib/utils'

type Props = {
    pubkey: string
}

const AwardedByUserCard: React.FC<Props> = ({ pubkey }) => {
    const { events: userProfileEvents } = useNostrEvents({
        filter: {
            authors: [pubkey],
            kinds: [0],
            since: 0,
            limit: 1,
        },
    })

    return (
        <>
            {userProfileEvents.sort(sortBy('-created_at')).map((user, i) => {
                const userProfile: ProfileData | undefined = parseContent(
                    user.content
                )
                if (!userProfile || i > 0) return null

                return (
                    <Box
                        key={`user-${i}`}
                        display='flex'
                        flexDirection='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        sx={{
                            // p: '1rem',
                            border: '0px solid #eee',
                            borderRadius: '.5rem',
                            // boxShadow: '1',
                            // width: '150px',
                        }}
                    >
                        <Box sx={{ pr: '.5rem' }}>
                            <Avatar
                                src={userProfile?.picture}
                                sx={{
                                    width: '2rem',
                                    height: '2rem',
                                }}
                            />
                        </Box>
                        <Box display='flex'>
                            <Typography
                                component='div'
                                variant='body1'
                                fontWeight='fontWeightRegular'
                            >
                                {userProfile?.display_name}
                            </Typography>
                        </Box>
                    </Box>
                )
            })}
        </>
    )
}

export default React.memo(AwardedByUserCard)
