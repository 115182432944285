import { Box, Container } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { CreatedBadgesContextProvider } from '../../app/CreatedBadgesContext'
import { useAuth } from '../../app/useAuth'
import Footer from '../../components/Layout/Footer'
import Layout from '../../components/Layout/Layout'
import Masthead from '../../components/Layout/Masthead'

const CreatedBadgesWrapper = () => {
    return (
        <CreatedBadgesContextProvider>
            <Layout />
        </CreatedBadgesContextProvider>
    )
}

export default CreatedBadgesWrapper
