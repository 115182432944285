import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useNavigate } from 'react-router-dom'
import { boolean } from 'yup'

type AuthContextState = {
    isSignedIn: () => boolean
    signin: (pubkey: string, targetUrl?: string) => void
    signout: () => void
    getPubkey: () => string | null
    principalPubkey: string | null
    hasWindowDotNostr: boolean
}

export const AuthContext = createContext({} as AuthContextState)

type Props = { children: React.ReactNode }

export const AuthContextProvider: React.FC<Props> = ({ children }) => {
    const navigate = useNavigate()
    const isFirstRender = useRef(true)
    const [hasWindowDotNostr, setHasWindowDotNostr] = useState(false)

    const signin = (pubkey: string, targetUrl?: string) => {
        localStorage.setItem('pubkey', pubkey)
        if (!!targetUrl) {
            navigate(targetUrl)
        } else {
            navigate(`/badge/creations`)
        }
    }

    const isSignedIn = () => {
        return !!localStorage.getItem('pubkey')
    }

    const signout = () => {
        localStorage.removeItem('pubkey')
        navigate('/')
    }

    const getPubkey = () => {
        return localStorage.getItem('pubkey')
    }

    const principalPubkey = useMemo(() => {
        return !!getPubkey() ? getPubkey() : null
    }, [getPubkey])

    useEffect(() => {
        if (isFirstRender.current) {
            // @ts-ignore: Property nostr does not exist
            if (window.nostr) {
                // console.log('Sign In: Window.Nostr exists...')
                setHasWindowDotNostr(true)
            } else {
                // console.log('Sign In: Window.Nostr does not exist...')
            }

            isFirstRender.current = false
        }
    }, [isFirstRender])

    const value = {
        isSignedIn,
        signin,
        signout,
        getPubkey,
        principalPubkey,
        hasWindowDotNostr,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}
