import { TextField } from '@mui/material'
import { useField } from 'formik'

type Props = {
    label: string | React.ReactElement
    name: string
    type?: 'password' | 'text'
    id?: string
    errorText?: string
    helperText?: string | React.ReactElement
    disabled?: boolean
    style?: React.CSSProperties
    [x: string]: any
}

const AppTextField: React.FC<Props> = (props) => {
    const {
        label,
        type = 'text',
        errorText,
        helperText = '',
        disabled = false,
        style,
    } = props
    const [field, meta] = useField(props)
    const errorMessage =
        !!meta.error && meta.touched ? meta.error : '' || errorText

    return (
        <TextField
            {...field}
            label={label}
            type={type}
            error={!!errorMessage}
            helperText={errorMessage || helperText}
            disabled={disabled}
            style={style}
        />
    )
}

export default AppTextField
