import { Box } from '@mui/material'
import AppTextField from '../../components/FormControls/AppTextField'
import { Fields } from './AwardBadgeForm'
import { FormikProps } from 'formik'
import { useEffect } from 'react'

interface Props extends Partial<FormikProps<Fields>> {
    onActivateValidation: () => void
}

const AwardBadgeFields: React.FC<Props> = (props) => {
    const { errors, onActivateValidation } = props

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0) {
            onActivateValidation()
        }
    }, [errors, onActivateValidation])

    return (
        <Box display='flex'>
            <AppTextField
                name='username'
                label='Username'
                type='text'
                error={!!errors?.username}
                helperText='Enter a Username (NIP-05) or Public Key (npub or hex)'
                style={{ width: '100%' }}
            />
        </Box>
    )
}

export default AwardBadgeFields
