import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ImageIcon from '@mui/icons-material/Image'
// import { parseBadgeEvent } from '../../lib/utils'
import { parseBadgeEvent } from '../../../lib/utils'
import AwardedByUserCard from '../../../components/Cards/AwardedByUserCard'

interface Props {
    stringifiedBadgeEvent: string
}

const EarnedBadgeListCard: React.FC<Props> = ({ stringifiedBadgeEvent }) => {
    const badge = parseBadgeEvent(JSON.parse(stringifiedBadgeEvent))

    // console.log(
    //     'Earned Badge List Card: badgeEvent:',
    //     JSON.parse(stringifiedBadgeEvent)
    // )
    // console.log('Earned Badge List Card: badgeData:', badge)

    return (
        <Box
            display='flex'
            flexDirection='column'
            sx={{
                my: '1rem',
                p: '.5rem',
                border: '1px solid lightgray',
                borderRadius: '.25rem',
            }}
        >
            <Box
                key={`badge-${badge?.info.identifier}`}
                display='flex'
                alignContent='flex-start'
            >
                {!!badge && !!badge.images.lThumb && (
                    <Box
                        component='img'
                        src={badge.images.lThumb}
                        sx={{
                            width: '92px',
                            height: '92px',
                            borderRadius: '.25rem',
                            backgroundColor: 'lightgray',
                        }}
                    />
                )}
                {!!badge && !!badge.images.thumb && (
                    <Box
                        component='img'
                        src={badge.images.thumb}
                        sx={{
                            width: '92px',
                            height: '92px',
                            borderRadius: '.25rem',
                            backgroundColor: 'lightgray',
                        }}
                    />
                )}
                {!badge ||
                    (!badge.images.lThumb && !badge.images.thumb && (
                        <ImageIcon
                            accentHeight='0'
                            sx={{
                                width: '92px',
                                height: '92px',
                                borderRadius: '.25rem',
                                color: 'lightgray',
                            }}
                        />
                    ))}
                <Box
                    display='flex'
                    flexDirection='column'
                    flexGrow={1}
                    sx={{
                        pl: '1rem',
                        // border: '1px solid gray',
                    }}
                >
                    <Typography
                        variant='h6'
                        fontWeight='fontWeightRegular'
                        color='text.primary'
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                            width: 'inherit',
                            display: '-webkit-box',
                            '-webkit-line-clamp': '1',
                            '-webkit-box-orient': 'vertical',
                        }}
                    >
                        {badge?.info.name}
                    </Typography>
                    {/* <Box
                        display='flex'
                        flexDirection='column'
                        justifyContent='flex-start'
                        // alignItems='center'
                        gap={2}
                        sx={{ my: '1rem' }}
                    > */}
                    <Typography variant='body2' sx={{ mb: '.33rem' }}>
                        Awarded by:{' '}
                    </Typography>
                    <AwardedByUserCard pubkey={badge?.info.author} />
                    {/* </Box> */}
                    {/* <Typography
                        variant='body1'
                        color='text.primary'
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'break-spaces',
                            textOverflow: 'ellipsis',
                            width: '240px',
                            height: '3.25rem',
                            // display: 'inline-block',
                            border: '0px solid gray',
                            display: '-webkit-box',
                            '-webkit-line-clamp': '2',
                            '-webkit-box-orient': 'vertical',
                        }}
                    >
                        {badge?.info.description}
                    </Typography> */}
                    {/* <Box
                        display='flex'
                        justifyContent='space-evenly'
                        sx={{ border: '0px solid gray', mt: '.5rem' }}
                    >
                        <Button
                            onClick={() =>
                                navigate(`/badge/awards/${badge.naddr}`)
                            }
                            variant='contained'
                            size='small'
                            sx={{ mr: '.5rem', flexGrow: '1' }}
                        >
                            Awards
                        </Button>
                        <Button
                            onClick={() =>
                                navigate(`/badge/details/${badge.naddr}`)
                            }
                            variant='contained'
                            size='small'
                            sx={{ mx: '.5rem', flexGrow: '1' }}
                        >
                            Details
                        </Button>
                    </Box> */}
                </Box>
            </Box>

            {/* <Typography variant='body2'>{badge?.info.identifier}</Typography> */}
        </Box>
    )
}

export default React.memo(EarnedBadgeListCard)
