import React from 'react'
import { Box, Button, Typography } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import ImageIcon from '@mui/icons-material/Image'
// import { parseBadgeEvent } from '../../lib/utils'
import { getAddressPointer } from '../../../lib/utils'
import BadgeListCard from '../../../components/Cards/BadgeListCard'
import { useNostrEvents } from 'nostr-react'
import EarnedBadgeListCard from './EarnedBadgeListCard'
import { AddressPointer } from 'nostr-tools/nip19'

interface Props {
    badgeNaddr: string
}

const EarnedBadgeListCardContainer: React.FC<Props> = ({ badgeNaddr }) => {
    const navigate = useNavigate()
    console.log('Earned Badge List Card Container: badgeNaddr:', badgeNaddr)

    let addressPointer: AddressPointer = {
        identifier: '',
        kind: 0,
        pubkey: '',
    }

    if (badgeNaddr.startsWith('naddr1')) {
        addressPointer = getAddressPointer(badgeNaddr)
    } else if (badgeNaddr.includes(':')) {
        const naddrArray = badgeNaddr.split(':')
        if (naddrArray.length != 3)
            throw new Error(
                'Earned Badge List Container: Invalid naddr provided.'
            )
        addressPointer.kind = Number(naddrArray[0])
        addressPointer.pubkey = naddrArray[1]
        addressPointer.identifier = naddrArray[2]
    }

    const { events: rawBadgeEvents } = useNostrEvents({
        filter: {
            authors: [addressPointer.pubkey],
            kinds: [30009],
            '#d': [addressPointer.identifier],
            limit: 1,
        },
    })

    // console.log(
    //     'Earned Badge List Card Container: rawBadgeEvents:',
    //     rawBadgeEvents
    // )

    // console.log(
    //     'Earned Badge List Card Container: addressPointer:',
    //     addressPointer
    // )

    return (
        <>
            {!!rawBadgeEvents && rawBadgeEvents.length > 0 && (
                <EarnedBadgeListCard
                    stringifiedBadgeEvent={JSON.stringify(rawBadgeEvents[0])}
                />
            )}
        </>
    )
}

export default React.memo(EarnedBadgeListCardContainer)
