import { Link, Box, Divider, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import CreateBadgeForm from './CreateBadgeForm'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const CreateBadge = () => {
    const navigate = useNavigate()
    const { badgeNaddr } = useParams()
    const isEditMode = !!badgeNaddr
    const title = isEditMode ? 'Edit Badge' : 'Create a Badge'
    const backButtonLink = isEditMode
        ? `/badge/details/${badgeNaddr}`
        : '/badge/creations'

    return (
        <Box>
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    mt: '0rem',
                }}
            >
                <Link
                    component='button'
                    variant='h6'
                    underline='none'
                    onClick={() => navigate(backButtonLink)}
                    style={{
                        display: 'flex',
                    }}
                >
                    <ChevronLeftIcon
                        color='primary'
                        style={{
                            fontSize: '2rem',
                        }}
                    />
                </Link>
                <Typography variant='h6' sx={{ pb: '.3rem' }}>
                    {title}
                </Typography>
                <Box sx={{ width: '32px', height: '32px' }}></Box>
            </Box>
            <Divider sx={{ my: '.5rem' }} />
            <Box sx={{ py: '1rem', minHeight: '20rem' }}>
                <CreateBadgeForm isEditMode={isEditMode} />
            </Box>
        </Box>
    )
}

export default CreateBadge
