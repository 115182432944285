import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Stack,
    Typography,
} from '@mui/material'
import AppTextField from '../../components/FormControls/AppTextField'
import { Fields } from './SignIn'
import { Field, Form, FormikProps } from 'formik'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

interface Props extends Partial<FormikProps<Fields>> {
    hasWindowDotNostr: boolean
    onActivateValidation: () => void
}

const FindUserFields: React.FC<Props> = (props) => {
    const { errors, hasWindowDotNostr, onActivateValidation, setFieldValue } =
        props

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0) {
            onActivateValidation()
        }
    }, [errors, onActivateValidation])

    useEffect(() => {
        if (!!setFieldValue) {
            setFieldValue('hasWindowDotNostr', hasWindowDotNostr!)
        }
    }, [hasWindowDotNostr, setFieldValue])

    return (
        <>
            {/* <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre> */}
            <Form id='sign-in-form'>
                <Stack spacing={2}>
                    {!hasWindowDotNostr && (
                        <>
                            <Stack>
                                <AppTextField
                                    name='username'
                                    label='Username (nip5, npub, or public key)'
                                    type='text'
                                    // error={!!errors?.username}
                                    helperText='Enter your Username (NIP-05) or Public Key (npub or hex)'
                                />
                                <Field
                                    type='hidden'
                                    name='hasWindowDotNostr'
                                    value={hasWindowDotNostr}
                                />
                            </Stack>
                        </>
                    )}

                    {/* <Stack>
                        <FormControl component='fieldset'>
                            <Field
                                type='checkbox'
                                name='agrees'
                                as={FormControlLabel}
                                control={<Checkbox />}
                                label={
                                    <label>
                                        I agree to the{' '}
                                        <Link
                                            to='/terms'
                                            style={{
                                                color: '#777',
                                                // textDecoration:
                                                //     'none',
                                                textDecorationColor:
                                                    'lightgray',
                                            }}
                                        >
                                            terms
                                        </Link>
                                    </label>
                                }
                            />
                            {!!errors && errors.agrees && (
                                <FormHelperText error={true}>
                                    {errors.agrees}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Stack> */}

                    {!hasWindowDotNostr && (
                        <Stack sx={{ textAlign: 'left' }}>
                            <Button
                                // onClick={handleSubmit}
                                type='submit'
                                form='sign-in-form'
                                variant='contained'
                                color='primary'
                            >
                                <Typography
                                    color='primary.contrastText'
                                    fontWeight='fontWeightMedium'
                                >
                                    Sign In
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                    {hasWindowDotNostr && (
                        <Box
                            sx={{
                                pt: '1rem',
                                display: 'flex',
                            }}
                        >
                            <Button
                                type='submit'
                                form='sign-in-form'
                                variant='contained'
                                color='primary'
                                sx={{ width: '100%' }}
                            >
                                <Typography
                                    color='primary.contrastText'
                                    fontWeight='fontWeightMedium'
                                >
                                    Sign In With Extension
                                </Typography>
                            </Button>
                        </Box>
                    )}
                </Stack>
            </Form>
        </>
    )
}

export default FindUserFields
