import React from 'react'
import { Box, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import ImageIcon from '@mui/icons-material/Image'
import { parseBadgeEvent } from '../../lib/utils'

interface Props {
    stringifiedBadge: string
}

const BadgeInfoCard: React.FC<Props> = ({ stringifiedBadge }) => {
    const badge = parseBadgeEvent(JSON.parse(stringifiedBadge))

    return (
        <Box
            key={`badge-${badge?.info.identifier}`}
            display='flex'
            alignContent='flex-start'
            sx={{
                pt: '.5rem',
            }}
        >
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                flexGrow={1}
                sx={{ pb: '.5rem', textAlign: 'center' }}
            >
                <Typography
                    variant='h6'
                    fontWeight='fontWeightRegular'
                    color='text.primary'
                    sx={{
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        width: '310px',
                        display: 'inline-block',
                        // border: '1px solid gray',
                    }}
                >
                    {badge?.info.name}
                </Typography>
            </Box>
        </Box>
    )
}

export default React.memo(BadgeInfoCard)
