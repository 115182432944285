import { Box } from '@mui/system'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import AppTextField from '../../components/FormControls/AppTextField'

interface Props {
    onActivateValidation: () => void
    isEditMode: boolean
}

const CreateBadgeFields: React.FC<Props> = ({
    onActivateValidation,
    isEditMode,
}) => {
    const { errors } = useFormikContext()

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0) {
            onActivateValidation()
        }
    }, [errors, onActivateValidation])

    return (
        <Box
            display='flex'
            flexDirection='column'
            gap={2}
            sx={
                {
                    // border: '1px solid gray'
                }
            }
        >
            <AppTextField
                label='Badge Id'
                name='id'
                helperText={!isEditMode ? "Example: 'bravery'" : ''}
                disabled={isEditMode}
                type='text'
                style={{ width: '100%' }}
            />

            <AppTextField
                label='Badge Name'
                name='name'
                helperText="Example: 'Medal of Bravery'"
                type='text'
                style={{ width: '100%' }}
            />

            <AppTextField
                label='Badge Description'
                name='description'
                helperText="Example: 'Awarded to users demonstrating bravery.'"
                type='text'
                style={{ width: '100%' }}
            />
        </Box>
    )
}

export default CreateBadgeFields
