import { Box, Divider, Typography } from '@mui/material'
import { NavLink } from 'react-router-dom'
import TermsOfParticipation from '../../components/Content/Terms/TermsOfParticipation'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const Terms = () => {
    return (
        <Box sx={{ p: '1rem' }}>
            <NavLink
                to='/'
                style={{
                    textDecoration: 'none',
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                }}
            >
                <ChevronLeftIcon
                    color='primary'
                    style={{ fontSize: '1.2rem' }}
                />
                <Typography
                    component='span'
                    color='primary'
                    sx={{
                        fontSize: '1.2rem',
                    }}
                >
                    Home
                </Typography>{' '}
            </NavLink>
            <Typography
                variant='h6'
                fontWeight='fontWeightRegular'
                sx={{ pt: '1rem' }}
            >
                Terms
            </Typography>

            <TermsOfParticipation />

            <Box
                display='flex'
                justifyContent='center'
                sx={{ pt: '1rem', pb: '1rem' }}
            >
                <Divider sx={{ width: '66%' }} />
            </Box>

            <Typography
                variant='h6'
                fontWeight='fontWeightLight'
                sx={{ pt: '1rem', textAlign: 'center' }}
            >
                Yada. Yada. <br />
                {/* Basically, let's nostr n' chill */}
                {/* Basically, nostr and chill 🤙 */}
                {/* Nostr n' chill 🤙 */}
            </Typography>
        </Box>
    )
}

export default Terms
