import { Link, Box, Divider, Typography } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreatedBadges } from '../../app/CreatedBadgesContext'
import { NostrReactEvent } from '../../app/types'
import BadgeInfoCard from '../../components/Cards/BadgeInfoCard'
import AddBadgeImagesForm from './AddBadgeImagesForm'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

const AddBadgeImages = () => {
    const navigate = useNavigate()
    const { badgeNaddr } = useParams()
    const { isLoading, getBadgeByNaddr, rawBadgeList } = useCreatedBadges()

    if (!badgeNaddr) throw new Error('Badge Details: Badge naddr not found')

    const badgeEvent = useMemo(() => {
        console.log(
            'Add Badge Images Form: Raw Badge List Length and Has Badge Naddr:',
            rawBadgeList.length,
            !!badgeNaddr
        )

        if (!!badgeNaddr) {
            if (!!rawBadgeList.length) {
                return getBadgeByNaddr(badgeNaddr)
            } else {
                return null
            }
        } else {
            return null
        }
    }, [badgeNaddr, getBadgeByNaddr, rawBadgeList.length])

    return (
        <>
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    mt: '0rem',
                }}
            >
                <Link
                    component='button'
                    variant='h6'
                    underline='none'
                    onClick={() => navigate(`/badge/details/${badgeNaddr}`)}
                    style={{
                        display: 'flex',
                    }}
                >
                    <ChevronLeftIcon
                        color='primary'
                        style={{
                            fontSize: '2rem',
                        }}
                    />
                </Link>
                <Typography variant='h6' sx={{ pb: '.3rem' }}>
                    Add Images
                </Typography>
                <Box sx={{ width: '32px', height: '32px' }}></Box>
            </Box>
            <Divider sx={{ my: '.5rem' }} />
            <Box sx={{ minHeight: '12rem' }}>
                {!isLoading && !!badgeEvent && (
                    <>
                        <BadgeInfoCard
                            stringifiedBadge={JSON.stringify(badgeEvent)}
                        />
                        <Box sx={{ py: '1rem', minHeight: '20rem' }}>
                            <AddBadgeImagesForm
                                badgeNaddr={badgeNaddr!}
                                stringifiedBadgeEvent={JSON.stringify(
                                    badgeEvent
                                )}
                            />
                        </Box>
                    </>
                )}
            </Box>
        </>
    )
}

export default AddBadgeImages
