import { Box, Typography } from '@mui/material'
import { Formik, Form, FormikHelpers } from 'formik'
import { nip19, UnsignedEvent } from 'nostr-tools'
import { useState } from 'react'
import appKeys from '../../app/appKeys'
import { BadgeData, NostrReactEvent } from '../../app/types'
import { useAuth } from '../../app/useAuth'
import useBadge from '../../app/useBadge'
import usePublish from '../../app/usePublish'
import AboutKeyManagement from '../../components/Content/KeyManagement/AboutKeyManagement'
import SignerComponent from '../../components/Signer/SignerComponent'
import Toggler from '../../components/Toggler/Toggler'
import { findUser } from '../../lib/userUtils'
import {
    checkValidityOfPrivateKey,
    MalformedKeyError,
    KeyMismatchError,
} from '../../lib/utils'
import AwardBadgeFields from './AwardBadgeFields'
import useAwardUserValidation from './useAwardBadgeValidation'

export type Fields = {
    username: string
    hasWindowDotNostr: boolean
    privateKey: string
}

interface Props {
    badgeData: BadgeData
}

const AwardBadgeForm: React.FC<Props> = ({ badgeData }) => {
    const { hasWindowDotNostr, getPubkey } = useAuth()
    const { validationSchema } = useAwardUserValidation()
    const {
        createBadgeAwardEvent,
        signAndBroadcast,
        broadcastEvent,
        createNoticeOfBadgeAward,
    } = usePublish()
    const { badgeNaddr } = useBadge()
    const initialValues: Fields = {
        username: '',
        hasWindowDotNostr,
        privateKey: '',
    }
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)

    const handleActivateValidation = () => {
        setValidateOnChange(true)
    }

    const handleSubmit = async (
        fields: Fields,
        { setFieldError, setFieldValue }: FormikHelpers<Fields>
    ) => {
        // console.log('Award Badge Form: Submit called...')

        let unsignedAwardEvent: UnsignedEvent | null = null
        let unsignedNoticeEvent: UnsignedEvent | null = null
        let recipientPubkey: string | null = null

        try {
            recipientPubkey = await findUser(fields.username)
            // console.log('Award Badge Form: Pubkey:', recipientPubkey)
            unsignedAwardEvent = createBadgeAwardEvent(
                badgeNaddr,
                getPubkey()!,
                recipientPubkey
            )
            // console.log(
            //     'Award Badge Form: Unsigned Award Event:',
            //     unsignedAwardEvent
            // )

            unsignedNoticeEvent = createNoticeOfBadgeAward(
                badgeData.info.name,
                getPubkey()!,
                recipientPubkey!,
                badgeData.images.lThumb!,
                badgeData.naddr!
            )

            // console.debug(
            //     'Create Badge Form: Unsigned Notice:',
            //     unsignedNoticeEvent
            // )
        } catch (error) {
            if (error instanceof Error) {
                setFieldError('username', error.message)
            }
        }

        if (!!unsignedAwardEvent && !!unsignedNoticeEvent) {
            if (hasWindowDotNostr) {
                // console.debug(
                //     'Create Badge Form: Has Window.Nostr: Using signing extension...'
                // )
                let signedEvent

                try {
                    // @ts-ignore: Property nostr does not exist
                    window.nostr
                        .signEvent(unsignedAwardEvent)
                        .then((signedEvent: NostrReactEvent) => {
                            // console.debug(
                            //     'Create Badge Form: Window.Nostr: Signed Event:',
                            //     signedEvent
                            // )
                            broadcastEvent(signedEvent)
                            setFieldValue('username', '')

                            // Update App Feed
                            signAndBroadcast(
                                unsignedNoticeEvent as NostrReactEvent,
                                appKeys.privateHex
                            )
                        })
                        .catch((error: Error) => console.error(error))
                    // console.debug(
                    //     'Create Badge Form: Signed Event:',
                    //     signedEvent
                    // )
                } catch (e) {
                    console.error(e)
                }
            } else {
                // console.debug(
                //     "Create Badge Form: Doesn't have Window.Nostr: Using naked private key..."
                // )

                try {
                    const privateHex = checkValidityOfPrivateKey(
                        fields.privateKey,
                        getPubkey()!
                    )

                    signAndBroadcast(
                        unsignedAwardEvent as NostrReactEvent,
                        privateHex
                    )

                    setFieldValue('username', '')
                } catch (e) {
                    if (e instanceof MalformedKeyError) {
                        console.error('Create Badge Form:', e)
                        setFieldError(
                            'privateKey',
                            'This is not a valid Private Key.'
                        )
                    }
                    if (e instanceof KeyMismatchError) {
                        console.error('Create Badge Form:', e)
                        setFieldError(
                            'privateKey',
                            'Sorry. This Private Key cannot sign this event. It does not correspond to the Public Key.'
                        )
                    }
                }
            }
        }
    }

    return (
        <>
            <Box sx={{ pb: '1rem' }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    validateOnChange={validateOnChange}
                    validateOnBlur={validateOnChange}
                    onSubmit={handleSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        /* and other goodies */
                    }) => {
                        const formikProps = {
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                        }

                        return (
                            <Form
                                id='award-badge-form'
                                style={{ border: '0px solid gray' }}
                            >
                                {/* {JSON.stringify(values)} */}
                                <Box sx={{ mb: '1rem', textAlign: 'left' }}>
                                    <Typography
                                        variant='h6'
                                        fontWeight='fontWeightRegular'
                                    >
                                        Award this Badge to:
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{ mb: hasWindowDotNostr ? 0 : '1rem' }}
                                >
                                    <AwardBadgeFields
                                        onActivateValidation={
                                            handleActivateValidation
                                        }
                                        {...formikProps}
                                    />
                                </Box>
                                <SignerComponent
                                    formName='award-badge-form'
                                    extensionButtonLabel='Award Badge'
                                    hasWindowDotNostr={hasWindowDotNostr}
                                />
                                {!hasWindowDotNostr && (
                                    <Box sx={{ py: '1rem' }}>
                                        <Toggler title='How are private keys handled?'>
                                            <AboutKeyManagement />
                                        </Toggler>
                                    </Box>
                                )}
                            </Form>
                        )
                    }}
                </Formik>
            </Box>
        </>
    )
}

export default AwardBadgeForm
