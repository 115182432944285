import { Box, Typography, Alert } from '@mui/material'

const AboutKeyManagement = () => (
    <Box>
        <Alert severity='info' sx={{ mt: '.5rem' }}>
            Signing extensions are fully supported. Using one is recommended
            where available.
        </Alert>

        <Typography sx={{ pt: '1rem' }}>
            If you are on a device that doesn't support signing extensions:
        </Typography>

        <ul style={{ paddingLeft: '15px' }}>
            <Typography variant='body1'>
                <li>
                    Your Private Key is only used to sign the verification note.
                </li>
                <li>
                    It is not persisted in any way unless you explicitely allow
                    it for future convenience.
                </li>
                <li>It is never passed over the wire.</li>
                <li>
                    If you allow it, your private key will be stored in your
                    browser's Local Storage. No measure is taken to secure it.
                    Do not use this option on public or shared devices.
                </li>
            </Typography>
        </ul>
    </Box>
)

export default AboutKeyManagement
