import { Box, Typography } from '@mui/material'

const PublicMasthead = () => {
    return (
        <Box
            sx={{
                // position: isSignedIn() ? 'fixed' : 'inherit',
                zIndex: '100',
                // width: isSignedIn() ? '442px' : 'inherit',
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'top',
                justifyContent: 'center',
                mb: '1rem',
                p: '1rem',
                backgroundColor: '#eeeeee',
                borderRadius: '.25rem',
                boxShadow: '3',
            }}
        >
            <Box
                display='flex'
                alignItems='center'
                sx={{ border: '0px solid gray' }}
            >
                <Typography
                    display='flex'
                    variant={'h4'}
                    fontWeight='fontWeightBold'
                    sx={{
                        pb: '0',
                        color: 'rebeccapurple',
                        // ml: '1rem',
                    }}
                >
                    Nostr
                </Typography>
                <Box
                    component='img'
                    src='/HeartBadge002.png'
                    sx={{
                        ml: '.5rem',
                        mr: '.25rem',
                        width: '35px',
                        height: '35px',
                    }}
                />
                <Typography
                    variant={'h4'}
                    fontWeight='fontWeightBold'
                    sx={{
                        color: 'rebeccapurple',
                    }}
                >
                    Badges
                </Typography>
            </Box>
        </Box>
    )
}

export default PublicMasthead
