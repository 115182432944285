import * as yup from 'yup'

const useAwardBadgeValidation = () => {
    const validationSchema = yup.object().shape({
        username: yup
            .string()
            .trim()
            .required(`Either a username (NIP-05) or Public Key is required.`),
        privateKey: yup
            .string()
            .nullable()
            .when('hasWindowDotNostr', {
                is: false,
                then: yup
                    .string()
                    .trim()
                    .required(
                        'Your Private Key (nsec or hex) is required to sign this event'
                    ),
                otherwise: yup.string().notRequired(),
            }),
    })

    return {
        validationSchema,
    }
}

export default useAwardBadgeValidation
