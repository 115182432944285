import React from 'react'
import AwardedUsers from './AwardedUsers'
import { AwardedUsersContextProvider } from './useAwardedUsers'

interface Props {
    badgeNaddr: string
}

const AwardedUsersContainer: React.FC<Props> = ({ badgeNaddr }) => {
    return (
        <AwardedUsersContextProvider>
            <AwardedUsers badgeNaddr={badgeNaddr} />
        </AwardedUsersContextProvider>
    )
}

export default React.memo(AwardedUsersContainer)
