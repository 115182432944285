import { Box } from '@mui/material'
import UserCardContainer from '../../../components/Cards/UserCardContainer'

type Props = {
    recipientsList: string[]
}

const RecipientsList: React.FC<Props> = ({ recipientsList }) => {
    // console.log('VList 2: From List:', fromList)
    return (
        <>
            {recipientsList.map((recipientPubkey, i) => (
                <Box key={`from-${recipientPubkey}`}>
                    <UserCardContainer pubkey={recipientPubkey} />
                </Box>
            ))}
        </>
    )
}

export default RecipientsList
