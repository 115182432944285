import { Box, Typography, Button } from '@mui/material'
import { BadgeData } from '../../app/types'
import ImageIcon from '@mui/icons-material/Image'
import { useNavigate } from 'react-router-dom'

interface Props {
    badgeData: BadgeData
}

const BadgeAwardHeaderCard: React.FC<Props> = ({ badgeData }) => {
    const navigate = useNavigate()

    return (
        <Box
            display='flex'
            flexDirection='column'
            sx={{
                p: '.5rem',
                // border: '1px solid lightgray',
                // borderRadius: '.25rem',
            }}
        >
            <Box
                key={`badge-${badgeData?.info.identifier}`}
                display='flex'
                flexDirection='row-reverse'
                alignContent='flex-start'
            >
                {!!badgeData && !!badgeData.images.lThumb && (
                    <Box
                        component='img'
                        src={badgeData.images.lThumb}
                        sx={{
                            width: '128px',
                            height: '128px',
                            borderRadius: '.25rem',
                            backgroundColor: 'lightgray',
                        }}
                    />
                )}
                {!badgeData ||
                    (!badgeData.images.mThumb && (
                        <ImageIcon
                            accentHeight='0'
                            sx={{
                                width: '128px',
                                height: '128px',
                                borderRadius: '.25rem',
                                color: 'lightgray',
                            }}
                        />
                    ))}
                <Box
                    display='flex'
                    flexDirection='column'
                    flexGrow={1}
                    sx={
                        {
                            // pl: '1rem',
                            // border: '1px solid gray',
                        }
                    }
                >
                    <Typography
                        variant='h5'
                        fontWeight='fontWeightRegular'
                        color='text.primary'
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            // width: '310px',
                            display: 'inline-block',
                            // border: '1px solid gray',
                        }}
                    >
                        {badgeData?.info.name}
                    </Typography>
                    <Typography
                        variant='body1'
                        color='text.primary'
                        sx={
                            {
                                // overflow: 'hidden',
                                // whiteSpace: 'break-spaces',
                                // textOverflow: 'ellipsis',
                                // width: '260px',
                                // height: '6.25rem',
                                // display: 'inline-block',
                                // border: '1px solid gray',
                                // display: '-webkit-box',
                                // '-webkit-line-clamp': '4',
                                // '-webkit-box-orient': 'vertical',
                            }
                        }
                    >
                        {badgeData?.info.description}
                    </Typography>
                </Box>
            </Box>
            {/* <Typography variant='body2'>{badgeData?.info.identifier}</Typography> */}
        </Box>
    )
}

export default BadgeAwardHeaderCard
