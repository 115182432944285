import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { parseBadgeEvent } from '../lib/utils'
import { useCreatedBadges } from './CreatedBadgesContext'
import { BadgeData } from './types'

const useBadge = () => {
    const { badgeNaddr } = useParams()
    const { isLoading, getBadgeByNaddr, rawBadgeList } = useCreatedBadges()
    const [badgeData, setBadgeData] = useState<BadgeData>()

    if (!badgeNaddr) throw new Error('Badge Details: Badge naddr not found')

    const badgeEvent = useMemo(() => {
        // console.log(
        //     'Use Badge: Raw Badge List Length and Has Badge Naddr:',
        //     rawBadgeList.length,
        //     !!badgeNaddr
        // )

        if (!!badgeNaddr) {
            if (!!rawBadgeList.length) {
                return getBadgeByNaddr(badgeNaddr)
            } else {
                return null
            }
        } else {
            return null
        }
    }, [badgeNaddr, getBadgeByNaddr, rawBadgeList.length])

    useEffect(() => {
        // console.log('Use Badge: Badge Event:', badgeEvent)
        if (!!badgeEvent) {
            const newBadgeData = parseBadgeEvent(badgeEvent)
            setBadgeData(newBadgeData)
        }
    }, [badgeEvent])

    return {
        badgeEvent,
        badgeData,
        badgeNaddr,
        isLoading,
    }
}

export default useBadge
