const appKeys = {
    nsec: 'nsec1dks5ky69se35g08a525tkwkmgeyzsug0yrm59jecp9z8n5crdetsydznm8',
    privateHex:
        '6da14b13458663443cfda2a8bb3adb464828710f20f742cb38094479d3036e57',
    npub: 'npub14vata7awgkfcvllttadv2rlfs3gutqd30l48nvx3pcecf7sr8cdqc2wdny',
    publicHex:
        'ab3abefbae4593867feb5f5ac50fe98451c581b17fea79b0d10e3384fa033e1a',
}

export default appKeys
