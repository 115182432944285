import { Backdrop, CircularProgress } from '@mui/material'
import { useNostrEvents } from 'nostr-react'
import { nip19 } from 'nostr-tools'
import { AddressPointer } from 'nostr-tools/nip19'
import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { sortBy } from 'sort-by-typescript'
import { NostrReactEvent } from './types'
import { useAuth } from './useAuth'

type CreatedBadgesContextState = {
    principalPubkey: string
    rawBadgeList: NostrReactEvent[]
    isLoading: boolean
    getBadgeByNaddr: (naddr: string) => NostrReactEvent
}

export const CreatedBadgesContext = createContext(
    {} as CreatedBadgesContextState
)

type Props = { children: React.ReactNode }

export const CreatedBadgesContextProvider: React.FC<Props> = ({ children }) => {
    const { getPubkey } = useAuth()
    const isFirstRender = useRef(true)
    const isFirstLoad = useRef(true)
    const [isLoading, setIsLoading] = useState(true)

    if (!getPubkey())
        throw new Error('Created Badges Context: Pubkey not found')
    const principalPubkey = getPubkey()!

    const {
        events: rawBadgeList,
        onEvent,
        isLoading: isLoadingEvents,
    } = useNostrEvents({
        filter: {
            kinds: [30009],
            authors: [principalPubkey!],
        },
    })

    const updateShowSpinner = () => {
        if (isLoading && isFirstLoad.current) {
            isFirstLoad.current = false
            // Give the component time to marshall the events into something it can use
            setTimeout(() => {
                setIsLoading(false)
            }, 500)
            // setIsLoading(false)
        }
    }

    useEffect(() => {
        // console.log(
        //     'Created Badges Context: Load Badges: Is Loading Events:',
        //     isLoadingEvents
        // )
        if (!isLoadingEvents) {
            updateShowSpinner()
        }
    }, [isLoadingEvents])

    const listenToEvents = useCallback(() => {
        onEvent((event) => {
            // console.log('Created Badges Context: Load Badges: Event:', event)
            updateShowSpinner()
        })
    }, [onEvent, updateShowSpinner])

    useEffect(() => {
        if (isFirstRender.current) {
            // console.log(
            //     'Created Badges Context: Load Badges: Use Effect: Listen to Events called...'
            // )
            listenToEvents()
            isFirstRender.current = false
        }
    })

    const handleClose = () => {
        setIsLoading(false)
    }

    const getBadgeByNaddr = (naddr: string) => {
        // console.log('Created Badges Context: Get Badge By Naddr: naddr:', naddr)
        // console.log(
        //     'Created Badges Context: Raw Badge List Length:',
        //     rawBadgeList.length
        // )
        let { type, data } = nip19.decode(naddr)
        return rawBadgeList
            .sort(sortBy('-created_at'))
            .find(
                (badgeEvent) =>
                    badgeEvent.tags[0][1] ===
                    (data as AddressPointer).identifier
            ) as NostrReactEvent
    }

    const value = {
        principalPubkey,
        rawBadgeList,
        isLoading,
        getBadgeByNaddr,
    }

    return (
        <CreatedBadgesContext.Provider value={value}>
            {children}

            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoading}
                onClick={handleClose}
            >
                <CircularProgress />
            </Backdrop>
        </CreatedBadgesContext.Provider>
    )
}

export const useCreatedBadges = () => {
    return useContext(CreatedBadgesContext)
}
