import { Box, Divider, IconButton, Link } from '@mui/material'
import { useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'

type Props = {
    title: string
    children: React.ReactNode
}

const Toggler: React.FC<Props> = ({ title, children }) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)

    const toggle = (
        e:
            | React.MouseEvent<HTMLAnchorElement, MouseEvent>
            | React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => {
        // console.log('Toggler clicked...')
        e.preventDefault()
        setIsExpanded((prevState) => !prevState)
    }

    return (
        <Box>
            <>
                <Link
                    variant='body1'
                    color='text.primary'
                    underline='none'
                    href=''
                    onClick={toggle}
                >
                    {title}
                    <IconButton aria-label='expand'>
                        {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </Link>

                {isExpanded && (
                    <>
                        {children}
                        <Box
                            display='flex'
                            justifyContent='center'
                            sx={{ pt: '1rem', pb: '1rem' }}
                        >
                            <Divider
                                sx={{
                                    width: '66%',
                                    // borderColor: 'gray',
                                }}
                            />
                        </Box>
                    </>
                )}
            </>
        </Box>
    )
}

export default Toggler
