import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import AppTextField from '../../components/FormControls/AppTextField'

interface Props {
    onActivateValidation: () => void
}

const AddBadgeImagesFields: React.FC<Props> = ({ onActivateValidation }) => {
    const { errors } = useFormikContext()

    useEffect(() => {
        if (errors && Object.keys(errors).length > 0) {
            onActivateValidation()
        }
    }, [errors, onActivateValidation])

    return (
        <Box display='flex' flexDirection='column' gap={2}>
            <AppTextField
                label={
                    <>
                        Full Size Badge Image{' '}
                        <Typography component='span' sx={{ fontSize: '.8rem' }}>
                            (1024 x 1024)
                        </Typography>
                    </>
                }
                name='image'
                helperText={
                    <>
                        Example: https://example.com/bravery-badge.png
                        <br />
                        Recommended Dimensions: 1024 width x 1024 height in
                        pixels
                    </>
                }
                type='text'
                style={{ width: '100%' }}
            />

            <AppTextField
                label={
                    <>
                        XL Badge Thumbnail{' '}
                        <Typography component='span' sx={{ fontSize: '.8rem' }}>
                            (512 x 512)
                        </Typography>
                    </>
                }
                name='xlThumb'
                helperText='Extra large thumbnail'
                type='text'
                style={{ width: '100%' }}
            />

            <AppTextField
                label={
                    <>
                        L Badge Thumbnail{' '}
                        <Typography component='span' sx={{ fontSize: '.8rem' }}>
                            (256 x 256)
                        </Typography>
                    </>
                }
                name='lThumb'
                helperText='Large thumbnail'
                type='text'
                style={{ width: '100%' }}
            />

            <AppTextField
                label={
                    <>
                        M Badge Thumbnail{' '}
                        <Typography component='span' sx={{ fontSize: '.8rem' }}>
                            (64 x 64)
                        </Typography>
                    </>
                }
                name='mThumb'
                helperText='Medium thumbnail'
                type='text'
                style={{ width: '100%' }}
            />

            <AppTextField
                label={
                    <>
                        S Badge Thumbnail{' '}
                        <Typography component='span' sx={{ fontSize: '.8rem' }}>
                            (32 x 32)
                        </Typography>
                    </>
                }
                name='sThumb'
                helperText='Small thumbnail'
                type='text'
                style={{ width: '100%' }}
            />

            <AppTextField
                label={
                    <>
                        XS Badge Thumbnail{' '}
                        <Typography component='span' sx={{ fontSize: '.8rem' }}>
                            (16 x 16)
                        </Typography>
                    </>
                }
                name='xsThumb'
                helperText='Extra small thumbnail'
                type='text'
                style={{ width: '100%' }}
            />
        </Box>
    )
}

export default AddBadgeImagesFields
