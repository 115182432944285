import React from 'react'
import { Box, Typography, Button, Divider } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit'
import { useEarnedBadges } from '../../../app/useEarnedBadges'
import EarnedBadgeListCardContainer from './EarnedBadgeListCardContainer'

interface Props {
    targetPubkey: string
}

const EarnedBadges: React.FC<Props> = ({ targetPubkey }) => {
    const { isLoading, uniqueEarnedBadgesAddrList } = useEarnedBadges()
    const navigate = useNavigate()

    // useEffect(() => {
    //     console.log('Earned Badges: Is Loading Earned Badge Events:', isLoading)
    // }, [isLoading])

    // useEffect(() => {
    //     console.log(
    //         'Earned Badges: Earned Badges Addr List:',
    //         uniqueEarnedBadgesAddrList
    //     )
    // }, [uniqueEarnedBadgesAddrList])

    return (
        <>
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    mt: '0rem',
                    // pb: '.75rem',
                    // border: '1px solid gray',
                }}
            >
                <Typography variant='h6' sx={{ pb: '.3rem' }}>
                    Badges I've Earned
                </Typography>
                {/* {!isLoading && uniqueEarnedBadgesAddrList.length > 0 && (
                    <Button
                        variant='text'
                        size='medium'
                        startIcon={<EditIcon />}
                        onClick={() => navigate('/badge/create')}
                    >
                        Manage
                    </Button>
                )} */}
            </Box>
            <Divider sx={{ mt: '.5rem' }} />
            <Box
                sx={{
                    // mt: '2rem',
                    minHeight: '20rem',
                }}
            >
                {!isLoading &&
                    !!uniqueEarnedBadgesAddrList &&
                    uniqueEarnedBadgesAddrList.length === 0 && (
                        <Box
                            display='flex'
                            flexDirection='column'
                            alignItems='center'
                            gap={4}
                            sx={{
                                mt: '1.5rem',
                                p: '1rem',
                                border: '1px solid lightgray',
                                borderRadius: '.25rem',
                                textAlign: 'center',
                            }}
                        >
                            <Typography
                                variant='h6'
                                fontWeight='fontWeightRegular'
                            >
                                You haven't earned any badges yet.
                            </Typography>
                        </Box>
                    )}
                {!isLoading && uniqueEarnedBadgesAddrList.length > 0 && (
                    <>
                        {/* <Box>{uniqueEarnedBadgesAddrList.length}</Box> */}
                        {uniqueEarnedBadgesAddrList.map((badgeNaddr, i) => {
                            return (
                                <Box key={`badge-${badgeNaddr}`}>
                                    {/* {badgeNaddr} */}
                                    <EarnedBadgeListCardContainer
                                        badgeNaddr={badgeNaddr}
                                    />
                                    {/* <BadgeListCard
                                        stringifiedBadgeEvent={JSON.stringify(
                                            badge
                                        )}
                                    /> */}
                                </Box>
                            )
                        })}
                    </>
                )}
            </Box>
        </>
    )
}

export default React.memo(EarnedBadges)
