import { Box, Typography, Divider, Link } from '@mui/material'
import { Formik, FormikHelpers } from 'formik'
import { nip05, nip19 } from 'nostr-tools'
import { ProfilePointer } from 'nostr-tools/nip19'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import About from '../../components/Content/About/About'
import AppLink from '../../components/FormControls/AppLink'
import { useAuth } from '../../app/useAuth'
import SignInForm from './SignInForm'
import useSignInValidation from './useSignInValidation'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export type Fields = {
    username: string
    hasWindowDotNostr: boolean
}

const SignIn = () => {
    const { isSignedIn } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const targetUrl = !!location?.state?.targetUrl
        ? location.state.targetUrl
        : ''
    const isFirstRender = useRef(true)
    const { signin } = useAuth()
    const { validationSchema } = useSignInValidation()
    const initialValues: Fields = {
        username: '',
        hasWindowDotNostr: false,
    }
    const [validateOnChange, setValidateOnChange] = useState<boolean>(false)

    const handleActivateValidation = () => {
        setValidateOnChange(true)
    }

    const handleSubmit = async (
        fields: Fields,
        { setFieldError }: FormikHelpers<Fields>
    ) => {
        // console.log('Sign In: Form submitted...')

        if (hasWindowDotNostr) {
            await signInWithExtension()
            // console.log('Sign In: Signing in w/ ext...')
            return
        }

        let pubkey = ''

        if (fields.username.includes('.')) {
            try {
                const foundUser: ProfilePointer | null | undefined =
                    await searchNip05(fields.username)

                // console.log('Find User: Found User:', foundUser)

                if (foundUser?.pubkey) {
                    pubkey = foundUser.pubkey
                } else {
                    setFieldError('username', 'This user was not found.')
                    return
                }
            } catch (e) {
                console.error(e)
                setFieldError('username', 'This user was not found.')
                return
            }
        } else if (fields.username.startsWith('npub')) {
            pubkey = nip19.decode(fields.username).data as string
        } else {
            pubkey = fields.username
        }

        // console.log('Find User: Pubkey:', pubkey)

        signin(pubkey, targetUrl)

        // navigate(`/profile/${pubkey}`)
    }

    const searchNip05 = async (username: string) => {
        try {
            return await nip05.queryProfile(username)
        } catch (e) {
            console.error(e)
        }
    }

    const getPk = async () => {
        try {
            // @ts-ignore: Property nostr does not exist
            const pk = await window.nostr.getPublicKey()
            // console.log('Sign In: getPk:', pk)
            return pk
        } catch (e) {
            console.error('Sign In: Error:', e)
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            // @ts-ignore: Property nostr does not exist
            if (window.nostr) {
                // console.log('Sign In: Window.Nostr exists...')
            } else {
                // console.log('Sign In: Window.Nostr does not exist...')
            }

            // console.log('Sign In: Location State: targetUrl:', targetUrl)

            // const pk = getPk()
            // console.log('Sign In: PK:', pk)
            isFirstRender.current = false
        }
    }, [isFirstRender, targetUrl])

    const hasWindowDotNostr = useMemo(() => {
        // @ts-ignore: Property nostr does not exist
        return window.nostr !== undefined
        // @ts-ignore: Property nostr does not exist
    }, [])

    const signInWithExtension = async () => {
        const pubkey = await getPk()
        // console.log('Sign in: With Ext: Pubkey:', pubkey)

        signin(pubkey, targetUrl)
    }

    return (
        <Box sx={{ pt: '1rem', px: '1rem' }}>
            <Box
                display='flex'
                flexDirection='column'
                alignItems='center'
                sx={{ pb: '1rem', textAlign: 'center' }}
            >
                <Typography
                    variant='h5'
                    // fontWeight='fontWeightBold'
                    sx={{ pb: '.5rem' }}
                >
                    The Place Where Anybody
                    <br /> Can Create and Award <br />
                    Badges on Nostr
                </Typography>
                {/* <Typography
                    variant='h6'
                    fontWeight='fontWeightRegular'
                    sx={{ fontStyle: 'italic' }}
                >
                    For instance...
                </Typography> */}
                <Box
                    component='img'
                    src='/sample-badge-001.png'
                    sx={{
                        mt: '1rem',
                        mb: '.5rem',
                        // p: '1rem',
                        p: { xs: '.75rem', sm: '1rem' },
                        position: 'relative',
                        rotate: '-3deg',
                        // scale: '.88',
                        maxWidth: '100%',
                        border: '1px solid lightgray',
                        borderRadius: '.25rem',
                        boxShadow:
                            '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                    }}
                />
                {/* <About /> */}
                <Typography
                    variant='h6'
                    fontWeight='fontWeightRegular'
                    sx={{ pt: '1rem' }}
                >
                    Create your first badge today!
                </Typography>
            </Box>

            {isSignedIn() && (
                <Box display='flex' justifyContent='center'>
                    <Link
                        component='button'
                        variant='h6'
                        underline='none'
                        onClick={() => navigate(`/badge/creations`)}
                        style={{
                            display: 'flex',
                        }}
                    >
                        {/* <Typography variant='h6' sx={{ pb: '.3rem' }}> */}
                        Get Started
                        {/* </Typography> */}
                        <ChevronRightIcon
                            color='primary'
                            style={{
                                fontSize: '2rem',
                            }}
                        />
                    </Link>
                </Box>
            )}
            {!isSignedIn() && (
                <>
                    <Box
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        sx={{ pb: '1rem' }}
                    >
                        <Divider sx={{ width: '100%' }}>
                            Sign In to Get Started
                        </Divider>
                    </Box>

                    <Box sx={{ pt: '0rem' }}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            validateOnChange={validateOnChange}
                            validateOnBlur={validateOnChange}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                /* and other goodies */
                            }) => {
                                const formikProps = {
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                }
                                return (
                                    <SignInForm
                                        onActivateValidation={
                                            handleActivateValidation
                                        }
                                        hasWindowDotNostr={hasWindowDotNostr}
                                        {...formikProps}
                                    />
                                )
                            }}
                        </Formik>
                    </Box>
                </>
            )}
        </Box>
    )
}

export default SignIn
