import { Route, Routes } from 'react-router-dom'
import PublicLayout from './components/Layout/PublicLayout'
import LoginCheck from './LoginCheck'
import SignIn from './screens/SignIn/SignIn'
import Terms from './screens/Terms/Terms'
import About from './screens/About/About'
import CreateBadge from './screens/Badge/CreateBadge'
import BadgeDetails from './screens/Badge/BadgeDetails'
import BadgeCreations from './screens/Badge/BadgeCreations'
import CreationsWrapper from './screens/Badge/CreatedBadgesWrapper'
import ScrollToTop from './components/Layout/ScrollToTop'
import AddBadgeImages from './screens/Badge/AddBadgeImages'
import BadgeAwards from './screens/Badge/Awards/BadgeAwards'
import EarnedBadgesWrapper from './screens/Badge/Earned/EarnedBadgesWrapper'
import EarnedBadgesContainer from './screens/Badge/Earned/EarnedBadgesContainer'

const AppRouter = () => {
    return (
        <ScrollToTop>
            <Routes>
                <Route path='/' element={<PublicLayout />}>
                    <Route index element={<SignIn />} />
                    <Route path='terms' element={<Terms />} />
                    <Route path='about' element={<About />} />
                </Route>
                <Route element={<LoginCheck />}>
                    <Route element={<CreationsWrapper />}>
                        {/* <Route path='profile' element={<ProfileContainer />}>
                        <Route path=':user' element={<ProfileContainer />} />
                    </Route> */}
                        <Route
                            path='badge/creations'
                            element={<BadgeCreations />}
                        />
                        {/* <Route path='badge' element={<BadgeContainer />}> */}
                        <Route path='badge/create' element={<CreateBadge />} />
                        <Route
                            path='badge/create/:badgeNaddr'
                            element={<CreateBadge />}
                        />
                        {/* </Route> */}
                        <Route
                            path='badge/add-images/:badgeNaddr'
                            element={<AddBadgeImages />}
                        />
                        <Route
                            path='badge/details/:badgeNaddr'
                            element={<BadgeDetails />}
                        />
                        <Route
                            path='badge/awards/:badgeNaddr'
                            element={<BadgeAwards />}
                        />
                    </Route>
                    <Route element={<EarnedBadgesWrapper />}>
                        <Route
                            path='badges/earned'
                            element={<EarnedBadgesContainer />}
                        />
                    </Route>
                </Route>
            </Routes>
        </ScrollToTop>
    )
}

export default AppRouter
