import { nip19, nip05 } from 'nostr-tools'
import { ProfilePointer } from 'nostr-tools/nip19'

export const findUser = async (username: string) => {
    if (username.includes('.')) {
        try {
            const foundUser: ProfilePointer | null | undefined =
                await searchNip05(username)

            // console.log('Find User: Found User:', foundUser)

            if (foundUser?.pubkey) {
                return foundUser.pubkey
            } else {
                throw new Error('This user was not found.')
                // setFieldError('username', 'This user was not found.')
            }
        } catch (e) {
            console.error(e)
            throw new Error('This user was not found.')
            // setFieldError('username', 'This user was not found.')
        }
    } else if (username.startsWith('npub')) {
        try {
            // Throws error if invalid
            return nip19.decode(username).data as string
        } catch (e) {
            console.error(e)
            throw new Error('This user was not found.')
        }
    } else {
        try {
            const regExp = /[0-9A-Fa-f]{6}/g
            // console.log(
            //     'User Utils: Find User: Hex provided?',
            //     username.length === 64,
            //     regExp.test(username)
            // )

            // Attempt to encode the hex pubkey
            // Throws error if invalid
            if (username.length === 64 && regExp.test(username)) {
                // console.log('User Utils: Find User: Is Hex...')
                return username
            } else {
                throw new Error('This user was not found.')
            }
        } catch (e) {
            // console.error(e)
            throw new Error('This user was not found.')
        }
    }
}

const searchNip05 = async (username: string) => {
    try {
        return await nip05.queryProfile(username)
    } catch (e) {
        console.error(e)
    }
}
