import { Box, Typography, Divider, Link } from '@mui/material'
import { useEffect } from 'react'
import useBadge from '../../../app/useBadge'
import BadgeAwardHeaderCard from '../../../components/Cards/BadgeAwardHeaderCard'
import { useNavigate } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import AwardBadgeForm from '../../AwardBadge/AwardBadgeForm'
import AwardedUsers from './AwardedUsers'
import AwardedUsersContainer from './AwardedUsersContainer'

const BadgeAwards = () => {
    const navigate = useNavigate()
    const { badgeNaddr, badgeData } = useBadge()

    // useEffect(() => {
    //     console.log('Badge Awards: Badge Data:', badgeData)
    // }, [badgeData])

    return (
        <>
            <Box
                display='flex'
                justifyContent='space-between'
                sx={{
                    mt: '0rem',
                }}
            >
                <Link
                    component='button'
                    variant='h6'
                    underline='none'
                    onClick={() => navigate(`/badge/creations`)}
                    style={{
                        display: 'flex',
                    }}
                >
                    <ChevronLeftIcon
                        color='primary'
                        style={{
                            fontSize: '2rem',
                        }}
                    />
                </Link>
                <Typography variant='h6' sx={{ pb: '.3rem' }}>
                    Awards
                </Typography>
                <Box sx={{ width: '32px', height: '32px' }}></Box>
            </Box>
            <Divider sx={{ my: '.5rem' }} />
            <Box sx={{ mt: '1rem', minHeight: '12rem' }}>
                {!!badgeData && (
                    <Box display='flex' flexDirection='column' gap={2}>
                        <BadgeAwardHeaderCard badgeData={badgeData!} />
                        <AwardBadgeForm badgeData={badgeData} />

                        <AwardedUsersContainer
                            badgeNaddr={badgeNaddr.toString()}
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}

export default BadgeAwards
