import { Button, Typography, Box } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { useAuth } from '../../app/useAuth'
import { BadgeInfoFields } from '../../screens/Badge/CreateBadgeForm'
import AppTextField from '../FormControls/AppTextField'

interface Props {
    formName: string
    hasWindowDotNostr: boolean
    standardButtonLabel?: string
    extensionButtonLabel?: string
}

const SignerComponent: React.FC<Props> = ({
    formName,
    hasWindowDotNostr,
    standardButtonLabel,
    extensionButtonLabel,
}) => {
    // const { hasWindowDotNostr } = useAuth()
    const { errors } = useFormikContext<BadgeInfoFields>()

    return (
        <Box sx={{ textAlign: 'left' }}>
            {!hasWindowDotNostr && (
                <AppTextField
                    name='privateKey'
                    label='Your Private Key'
                    type='password'
                    helperText='Enter your Private Key (nsec or hex)'
                    error={!!errors?.privateKey}
                    style={{ width: '100%' }}
                />
            )}

            <Field
                type='hidden'
                name='hasWindowDotNostr'
                value={hasWindowDotNostr}
            />

            <Button
                type='submit'
                form={formName}
                variant='contained'
                color='primary'
                sx={{ mt: '1rem', width: '100%' }}
            >
                <Typography
                    color='primary.contrastText'
                    fontWeight='fontWeightMedium'
                >
                    {standardButtonLabel || extensionButtonLabel || 'Submit'}
                </Typography>
            </Button>
        </Box>
    )
}

export default SignerComponent
