import { Box, Avatar, Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { ProfileData } from '../../app/types'
import { parseContent } from '../../lib/utils'
import DisplayName from '../DisplayName/DisplayName'

type Props = {
    profileContent: string
    pubkey: string
}

const UserCardVertical: React.FC<Props> = ({ profileContent, pubkey }) => {
    const width = '7rem'
    const profileData = parseContent(profileContent) as ProfileData

    return (
        <>
            {/* <NavLink
                to={`/nostrich/${pubkey}`}
                style={{ textDecoration: 'none' }}
            > */}
            <Box
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                sx={{
                    pb: '.5rem',
                    border: '1px solid lightgray',
                    borderRadius: '.5rem',
                    // boxShadow: '1',
                    maxWidth: { width },
                }}
            >
                <Avatar
                    variant='square'
                    src={profileData?.picture}
                    sx={{
                        width: { width },
                        height: 'auto',
                        borderRadius: '.5rem .5rem 0 0',
                    }}
                />
                <Box
                    sx={{
                        m: '.5rem .5rem 0 .5rem',
                        width: '90px',
                        height: '25px',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipses',
                        border: '0px solid gray',
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        // component='div'
                        variant='body2'
                        fontWeight='fontWeightRegular'
                        color='text.primary'
                        sx={{ textDecoration: 'none' }}
                    >
                        <DisplayName profileData={profileData} />
                    </Typography>
                </Box>
            </Box>
            {/* </NavLink> */}
        </>
    )
}

export default React.memo(UserCardVertical)
